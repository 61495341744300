import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from 'layouts/loaderModal/LoadingModal';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function Admin_package_history() 
{
  const navigate = useNavigate();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [packageDetails,setPackageDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  
  const handlePageClick = (selected) =>
  {
    setCurrentPage(selected.selected);
  };

  
    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
  
    useEffect(() => {
      getNewKycDetails();
    }, [currentPage]);

    const getNewKycDetails = async () => 
    {
      setIsLoading(true);
      try
      {
        const response = await dashboardApi.get(`get-admin-package-history`, {
          params: {
            emp_id: emp_id,
            dateFrom: dateFrom,
            dateTo: dateTo,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if(response.data.status === 200)
        {
          setPackageDetails(response.data.data.data || []); 
          setNumber(response.data.data.last_page || 1);
         

          setDateFrom(response.data.from_date ? response.data.from_date : '');
          setDateTo(response.data.to_date ? response.data.to_date : '');
          setIsLoading(false);
        }
        else
        {
          setIsLoading(false);
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        setIsLoading(false);
        console.error('Error fetching countries:', error);
      }
    };

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <LoadingModal isLoading={isLoading} />
                  <div className="row">
                      <div class="col-sm-12">
                          <AdminDetailsHeader></AdminDetailsHeader>
                      </div>
                  </div>

                  <div className="  d-flex flex-wrap align-items-end px-0" >
                    <div className="col-sm-3 col-6 p-1 py-2">
                      <small>Date From</small>
                      <input type="date" className="form-control" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                    </div>

                    <div className="col-sm-3 col-6 p-1 py-2">
                      <small>Date To</small>
                      <input type="date" className="form-control" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                    </div>

                    <div className="col-sm-3 col-12 p-1 py-2 px-4">
                      <button className="btn btn-primary btn-sm" onClick={getNewKycDetails}>Search</button>
                    </div>
                  </div>

                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr style={{fontWeight:'700'}}>
                        <td>S.No</td>
                        <td>Company Name</td>
                        <td>Package Name</td>
                        <td>Product</td>
                        <td>Service</td>
                        <td>Duration</td>
                        <td>Package Type</td>
                        <td>Status</td>
                        <td>Created By</td>
                        <td>Executive Name</td>
                        <td>Created Date</td>
                        <td>Start Date</td>
                      </tr>
                      {packageDetails && packageDetails.map((npa,index)=>
                        <>
                          <tr>
                            <td rowspan="4">{index +1}</td>
                            <td>{npa.business_name}</td>
                            <td>{npa.package_name}</td>
                            <td>{npa.product_name}</td>
                            <td>{npa.service_name}</td>
                            <td>{npa.duration}</td>
                            <td>{npa.package_type}</td>
                            <td style={{fontWeight:'700'}}>
                              {npa.admin_status==0?<span style={{color:'red'}}>Pending</span>
                              :npa.admin_status==1?<span style={{color:'green'}}>Active</span>
                              :npa.admin_status==2?<span style={{color:'red'}}>Stop By A/S/V</span>
                              :npa.admin_status==3?<span style={{color:'red'}}>Stop By Due Pay</span>
                              :npa.admin_status==4?<span style={{color:'red'}}>Expire</span>
                              :''
                              }
                            </td>
                          
                            <td>{npa.created_by==1?'Sales':npa.created_by==2?'App':npa.created_by==3?'Dashboard':''}</td>
                            <td>{npa.emp_name}</td>
                            <td>{npa.created_date}</td>
                            <td>{npa.package_start_date}</td>
                            
                          </tr>
                          <tr><td colspan="11"><b> Category Name : </b>{npa.category_name}</td></tr>
                          <tr><td colspan="11"><b> Package Group Name : </b>{npa.package_group_name}</td></tr>
                          <tr style={{borderBottom:'solid #657789 4px'}}><td colspan="11"><b> Company Group Name : </b>{npa.comp_group_name}</td></tr>
                        </>
                      )}

                      <tr>
                        <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                            forcePage={currentPage}
                          />
                        </Pagination>
                        </td>
                      </tr>
                  </table>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Admin_package_history;
