import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from "components/MDBox";
import Header from "layouts/profile/components/Header";
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import './style.css';
import Popup from "layouts/profile/components/popup.js";

function Overview() {
  const token = secureLocalStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [attandence, getattandence] = useState([]);
  const [leave, getLeave] = useState([]);
  const [workingday, setWorkingDay] = useState([]);
  const department_name = secureLocalStorage.getItem('department');
  const [emp_dept,get_emp_dept] = useState('');
  const [leaveTakingData, setLeaveTakingData] = useState([]);
  const branch = secureLocalStorage.getItem("branch");
  const [wfh, getWfh] = useState([]);
  const [roster, setRoster] = useState([]);
  const { id } = useParams();
  const emp_id = id ? id : secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [attendance_data,set_attendance_data] = useState('');

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const years = Array.from({ length: 101 }, (_, i) => currentYear - 50 + i);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `view-daily-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          getattandence(response.data.data);
          get_emp_dept(response.data.department_id)
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [emp_id]);

  useEffect(() => {
    const fetchLeaveTakingData = async () => {
      try {
        const endpoint = `emp-leave-taken-list/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          setLeaveTakingData(response.data.leave_entries);
        } else {
          console.error("Error fetching leave taking data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave taking data:", error);
      }
    };
    fetchLeaveTakingData();
  }, [emp_id]);

  useEffect(() => {
    const fetchLeave = async () => {
      try {
        const endpoint = `leave-show-in-attendance/${branch}`;
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          getLeave(response.data.data);
        } else {
          console.error('Error fetching leave data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching leave data:', error);
      }
    };
    fetchLeave();
  }, [branch]);

  useEffect(() => {
    const fetchWorkingDay = async () => {
      try {
        const response = await dashboardApi.get(`show-working-days/${emp_id}`);
        if (response.data.status === 200) {
          setWorkingDay(response.data.data);
        } else {
          console.error('Error fetching working day data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching working day data:', error);
      }
    };
    fetchWorkingDay();
  }, [emp_id]);

  useEffect(() => {
    const fetchRoster = async () => {
      try {
        const endpoint = `roster-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          setRoster(response.data.data);
        } else {
          console.error("Error fetching roster data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching roster data:", error);
      }
    };
    fetchRoster();
  }, [emp_id]);

  useEffect(() => {
    const fetchWfhData = async () => {
      try {
        const endpoint = `work-from-home-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          getWfh(response.data.wfh_details);
        } else {
          console.error("Error fetching work from home data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching work from home data:", error);
      }
    };
    fetchWfhData();
  }, [emp_id]);

  const getStatusForDate = (date) => {
    const formattedDate = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;

    const attendance = attandence.find(item => item.date === formattedDate);
    const leaveEntry = leave.find(item => item.date === formattedDate);
    const workingDay = workingday.find(item => item.date === formattedDate);
    const rosterleave = roster.find(item => item.week_off === formattedDate);
    const wfhleave = wfh.find(item => item.date === formattedDate);
    const leavetaking = leaveTakingData.find(item=> item.date === formattedDate );
    const day = new Date(selectedYear, selectedMonth, date).getDay();
    const isWeekend = (day === 0 || day === 6);
    const details = { type: "default", login: "", logout: "", punch_In: "", remark: "", status: "", message: "",date:"",
    working_hours:"" ,idle_hours:"",break_hours:""};
    //console.log(attendance);
    if (workingDay) {
      details.type = "workingday";
      details.message = workingDay.reason;
      details.login = attendance && attendance.login_time || "";
      details.logout = attendance && attendance.logout_time || "";
      details.punch_In = attendance && attendance.punch_in_time || "";
      details.remark = attendance && attendance.remark || "";
      details.status = attendance && attendance.status;
      details.date = attendance && attendance.date;
      details.working_hours = attendance && attendance.working_hours;
      details.idle_hours = attendance && attendance.idle_time;
      details.break_hours = attendance && attendance.break_time;
    } else if (isWeekend && emp_dept != 4 ) {
      details.type = "weekend";
      details.message = "Week Off";
    } else if (rosterleave) {
      details.type = "roster";
      details.message = "Roster Off";
    } else if (leaveEntry && emp_dept != 4) {
      details.type = "leave";
      details.message = leaveEntry.leave_name;
    } else if (leavetaking) {
      details.type = "leavetaking";
      details.message = leavetaking.name;
    } else if (wfhleave) {
      details.type = "wfh";
      details.message = " Work From Home";
      details.login = attendance && attendance.login_time || "";
      details.logout = attendance && attendance.logout_time || "";
      details.remark = attendance && attendance.remark || "";
      details.status = attendance && attendance.status;
      details.date = attendance && attendance.date;
      details.punch_In = attendance && attendance.punch_in_time || "";
      details.working_hours = attendance && attendance.working_hours;
      details.idle_hours = attendance && attendance.idle_time;
      details.break_hours = attendance && attendance.break_time;
    } else if (attendance) {
      details.type = attendance.status;
      details.login = attendance.login_time || "";
      details.logout = attendance.logout_time || "";
      details.remark = attendance.remark || "";
      details.status = attendance.status;
      details.message = "";
      details.date = attendance.date;
      details.punch_In = attendance && attendance.punch_in_time || "";
      details.working_hours = attendance && attendance.working_hours;
      details.idle_hours = attendance && attendance.idle_time;
      details.break_hours = attendance && attendance.break_time;
    }

    return details;
  };

  const popup_details = (details) => {
    set_attendance_data(details);
  };
  const popup_details_leave = () => {
    set_attendance_data('');
  };

  const renderCalendar = () => {
    const firstDay = new Date(selectedYear, selectedMonth, 1).getDay();
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    const rows = [];
    let date = 1;

    for (let i = 0; i < 6; i++) {
      const cells = [];

      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          cells.push(<td key={`empty-${j}`}></td>);
        } else if (date > daysInMonth) {
          break;
        } else {
          const isToday = selectedYear === currentYear && selectedMonth === currentMonth && date === currentDate;

          const formattedDate = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;

          const details = getStatusForDate(date);

          let cellClass = "";
          switch (details.type) {
            case "workingday":
              cellClass = "workingday";
              break;
            case "weekend":
              cellClass = "weekend";
              break;
            case "roster":
              cellClass = "roster";
              break;
            case "leave":
              cellClass = "leave";
              break;
            case "leavetaking":
              cellClass = "leavetaking";
              break;
            case "wfh":
              cellClass = "wfh";
              break;
            case "Present":
              cellClass = "present";
              break;
            case "Absent":
              cellClass = "absent";
              break;
            case "Half Day":
              cellClass = "half_day";
              break;
            case "Short Leave":
              cellClass = "short_leave";
              break;
            default:
              break;
          }

          cells.push(
            <td
              key={`date-${date}`}
              className={`text-center ${isToday ? "today" : ""} ${cellClass}`}
              onMouseEnter={() => popup_details(details)} 
              onMouseLeave={() => popup_details_leave()}
            >
              {date}
            </td>
          );
          date++;
        }
      }

      rows.push(<tr key={`row-${i}`}>{cells}</tr>);
    }

    return rows;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mb={3}>
          <div className="custom-calendar-wrapper container my-4 col-sm-12 mx-0">
            <div className="calendar-and-popup d-flex justify-content-between">
              <div className="calendar-container" style={{ flex: 1 }}>
                <div className="calendar-header d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <select
                      id="year-select"
                      className="form-select form-control d-inline-block w-auto"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                    >
                      {years.map((y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                    </select>
                    <select
                      id="month-select"
                      className="form-select form-control d-inline-block w-auto mx-2"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                    >
                      {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((m, index) => (
                        <option key={index} value={index}>
                          {m}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="border p-2 rounded">
                  <table className="table calendar-table-data-row">
                    <thead>
                      <tr className="text-center">
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                      </tr>
                    </thead>
                    <tbody>{renderCalendar()}</tbody>
                  </table>
                </div>
              </div>

              <div className="popup-container">
                {attendance_data && (
                  <Popup data={attendance_data} />
                )}
              </div>
            </div>

            <style>{`
              .custom-calendar-wrapper .calendar-and-popup {
                display: flex;
                justify-content: space-between;
              }

              .custom-calendar-wrapper .calendar-container {
                flex: 1;
                padding-right: 20px;
              }

              .popup-container {
                width: 300px; /* Adjust as needed */
                padding:80px;
              }

              .custom-calendar-wrapper .calendar-table-data-row th,
              .custom-calendar-wrapper .calendar-table-data-row td {
                width: 50px;
                height: 70px;
                vertical-align: middle;
                position: relative;
                box-shadow: 0px 0px 7px 0px rgba(183, 182, 182, 0.72) inset;
              }

              .custom-calendar-wrapper .calendar-table-data-row th {
                border: 3px solid white;
                box-shadow: 0px 0px 7px 0px #b7b6b6 inset;
              }

              .custom-calendar-wrapper .calendar-table-data-row td {
                border: 3px solid white;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.today {
                background-color: #d1ecf1;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.workingday {
                background-color: #c3e6cb !important;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.weekend {
                background-color: #D3D3D9 !important;
                color: black;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.roster {
                background-color: #D3D3D9 !important;
                color: black;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.leave {
                background-color: #20B2AA !important;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.leavetaking {
                background-color: #A569BD !important;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.wfh {
                background-color: 	#4C9AFF !important;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.present {
                background-color:#4CAF50;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.absent {
                background-color: #FF4C4C;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.half_day {
                background-color: #FFA500;
                color: white;
                font-weight: bold;
              }

              .custom-calendar-wrapper .calendar-table-data-row td.short_leave {
                background-color: #FFD700;
                color: #155724;
                font-weight: bold;
              }
            `}</style>
          </div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginTop: '10px' }}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
           <span style={{ width: '20px', height: '20px', backgroundColor: '#c3e6cb', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
           <span>If Saturday/Sunday working</span>
          </div>

         <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '20px', height: '20px', backgroundColor: '#D3D3D9', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
          <span>Weekend/Roster Off</span>
         </div>

         <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#20B2AA', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Holiday</span>
           </div>

           <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#A569BD', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Leave</span>
           </div>

           <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#4C9AFF', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>WFH</span>
           </div>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#4CAF50', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Present</span>
           </div>
         </div>

         <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginTop: '10px' }}>
         <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#FF4C4C', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Absent</span>
           </div>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: ' #FFA500', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Half Day</span>
           </div>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <span style={{ width: '20px', height: '20px', backgroundColor: '#FFD700', display: 'inline-block', border: '1px solid #000', marginRight: '5px' }}></span>
             <span>Short Leave</span>
           </div>
           </div>

         

         

        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
