import React from 'react';
import './popup.css';
import  secureLocalStorage  from  "react-secure-storage";



const Popup = ({ data }) => {
   const  department = secureLocalStorage.getItem('department');
   //console.log(department);

  //console.log(data)
 
  return (
    <div className="smalls-cards-popup-s">
        {
           data.type == 'weekend' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-8'><center>Week Off</center><br/>
            </div>
            </div>
             </div>
        }
        {
           data.type == 'leave' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>{data.message}<br/>
            </div>
            </div>
             </div>
        }

        {
           data.type == 'roster' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>{data.message}<br/>
            </div>
            </div>
             </div>
        }

        {
           data.type == 'leavetaking' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>{data.message}<br/>
            </div>
            </div>
             </div>
        }

        {
           data.type == 'wfh' && data.date &&
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>WFH -{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
        }

         {
           data.type == 'Present' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
        }

          {
           data.type == 'Absent' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
         }

        {
           data.type == 'Half Day' &&
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
         }

{
           data.type == 'workingday' &&
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
         }

          {
           data.type == 'Short Leave' && 
           <div className="cards-popups" style={{width: "19rem" ,padding:"19px",backgroundColor:'yellow',color:'black' }}>
           <div className='row'>
            <div className='col-md-12'>
            <center>{data.date}</center>

            </div>
            
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px'}}>Login: {data.login}</span>
             &nbsp;&nbsp;<span style={{fontSize:'14px'}}>Logout: {data.logout}</span><br/>
            </div>
            <div className='col-md-12'>
              <span style={{fontSize:'14px'}}>Punch-In: {data.punch_In}</span>&nbsp;&nbsp;
              <span style={{fontSize:'14px'}}>Hours: {data.working_hours}</span><br/>
            </div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Idle Time: {data.idle_hours}</span>&nbsp;&nbsp;
            <span style={{fontSize:'14px'}}>Break Time: {data.break_hours}</span></div>

            <div className='col-md-12'><span style={{fontSize:'14px'}}>Status: {data.status}</span>&nbsp;&nbsp;
            </div>
            <div className='col-md-12'><span style={{fontSize:'14px',color:'red'}}>Remark: {data.remark}</span></div>
            </div>
         }
       
    </div>
  );
};

export default Popup;
