import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function TeamWFH() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const empid = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();
  const [wfhdata, getwfhdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permission, Setpermission] = useState();
  const [rejectionReason, setRejectionReason] = useState("");
  const [openmodal,setopenmodal] = useState('');
  const[task,settask] = useState('');
  const[taskid,settaskid] = useState('');
  //const [remark,setremark] = useState('');
  const [attendance,setattendance] = useState('');
  const [task_status,settask_status] = useState('');
  const[emp_name,set_emp_name] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [attendance_emp_id,set_attendance_emp_id] = useState('');
  const [attendance_date,set_attendance_date] = useState('');
  const [status,setstatus] = useState('');
  const [remark,setremark] = useState('');
 
  //const [currentPage, setCurrentPage] = useState(1);
  //const [totalPages, setTotalPages] = useState(1); 
  //const [postPerPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const handleStatusChange = (index, value) => {
    const updatedLeaveData = [...wfhdata];
    updatedLeaveData[index].status = value;
    getwfhdata(updatedLeaveData);
  };
  const handleReasonChange = (e) => {
    // Update the rejection reason when the user enters text
    setRejectionReason(e.target.value);
  };

  const handleSaveButtonClick = async ( id, index, value,emp_id) => {
    try {
      const response = await dashboardApi.post("work-from-home-status", {
        id:id,
        status: value,
        emp_id:emp_id,
        action_by:empid,
        rejectionReason: value === "2" ? rejectionReason : "", 
      });

      if (response.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
        fetchwfhdata();
      } else {
        console.error("Error updating leave status:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const viewtask = async (id)=>{
    //alert(id);
    settaskid(id);
    try {
      const endpoint = `get-wfh-task/${id}`;
      const response = await dashboardApi.get(endpoint);
     // console.log(response.data.status);
     if (response.data.status === 200) {
          

    //setselectedemployee(assignToValues);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }


    setopenmodal(true);
   

  }




  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchwfhdata();
  }, [currentPage,emp_name]);

  const  fetchwfhdata = async () => {
    try {
       const response = await dashboardApi.post('team-wfh-list', {
        emp_id: emp_id,
        per_page:postPerPage,
        page: currentPage + 1,
        emp_name:emp_name,
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        getwfhdata(employeeData);
        setNumber(response.data.last_page);
      } else {
        console.error("Error fetching:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `get-permission/${emp_id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          Setpermission(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
    //alert(newPage.selected);
  };

  const attendance_view = (emp_id,date)=>{
    set_attendance_date(date);
    set_attendance_emp_id(emp_id)
    setopenmodal(true);

  }
  const close_popup = ()=>{
    set_attendance_date('');
    set_attendance_emp_id('')
    setstatus('');
    setremark('');
     setopenmodal(false);
     setValidationErrors({})
 }

 const savedata = async(e)=>{
  e.preventDefault();
    const errors = {};
    if (!status) {
      errors.status = 'Status is required';
    }
    if (!remark) {
      errors.remark = 'Remark is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('emp_id', attendance_emp_id);
    formData.append('date',attendance_date);
    formData.append('remark',remark);
    formData.append('status',status);
    try {
      const response = await dashboardApi.post('wfh-attendance-update', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchwfhdata();
        close_popup();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else if(response.data.status ===204) {
        alert(response.data.message);
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
  };

 }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className="col-md-3" style={{float:'right'}}>

              <input 
                 type="text" 
                 className="form-control" 
                 placeholder="Search Name" 
                 value ={emp_name}
                 onChange={(e) => set_emp_name(e.target.value)}
                />

              </div>
              <br/><br/>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>from</th>
                        <th>To</th>
                        <th>No Of Days</th>
                        <th style={{minWidth:'110px'}}>Reason</th>
                        <th>Rejected Reason</th>
                        <th>Attendance Status</th>
                        <th>Action</th>
                        <th style={{minWidth:'200px'}}>Task</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wfhdata.map((wfh, index) => (
                        <tr key={wfh.id}>
                          <td>{index + 1}</td>
                          <td>
                            {wfh.emp_fname}  {wfh.emp_lame}
                          </td>
                          <td>{wfh.days_from}</td>
                          <td>{wfh.days_to}</td>
                          <td>{wfh.no_of_days}</td>
                          <td>{wfh.reason_for_wfh}</td>
                          <th>{wfh.rejection_reason}</th>
                          <td>{wfh.attendance_status}</td>
                          <td style={{ minWidth: '200px' }}>
                          {(() => {
                            const createdDate = new Date(wfh.created_date); 
                            const currentDate = new Date(); 
                            const daysDifference = Math.floor((currentDate - createdDate) / (1000 * 60 * 60 * 24)); 
                          if (daysDifference <= 38) {
                            return (
                             <>
                             <select
                                className="form-control"
                                value={wfh.status || ""}
                                onChange={(e) => handleStatusChange(index, e.target.value)}
                              >
                               <option value="">Select Status</option>
                               <option value="1">Allowed</option>
                               <option value="2">Not Allowed</option>
                              </select>
                              {wfh.status === "2" && (
                              <input
                                 type="text"
                                 className="form-control"
                                 placeholder="Enter rejection reason"
                                 onChange={handleReasonChange}
                                required
                              />
                              )}
                              <button
                                variant="primary"
                                className="mt-2 ms-6 btn btn-info"
                                size="lg"
                                block="block"
                                type="button"
                                onClick={() =>
                                 handleSaveButtonClick(wfh.id, index, wfh.status, wfh.employee_id)
                                 }
                                >
                                 Save
                                 </button>
                                 </>
                                 );
                                } else {
                                   return <p>Time Close For Action On Request</p>;
                                 }
                                })()}
                               </td>
                          <td>
                            <Link  to = {`/view-wfh-description/${wfh.id}`}className="btn btn-info btn-sm">View</Link>
                            &nbsp;&nbsp;
                             <button className="btn btn-info btn-sm" onClick={() => attendance_view(wfh.employee_id,wfh.days_from)}>Attendance</button>
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                  {wfhdata.length > 0 && (
                   <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
              </div>
            </Card>
            <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px'} }}
      >
        <DialogTitle>Attendance</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                   <select className="form-control"
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                   
                   >
                    <option value=''>Select Status</option>
                    <option value='1'>Present</option>
                    <option value = '2'>Half Day</option>
                    <option value = '3'>Short Leave</option>
                    <option value = '0'>Absent</option>

                   </select>
                   {validationErrors.status && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                     )}

                </div>

            </div>
            <br/>

            <div class ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                           value={remark}
                           onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                     {validationErrors.remark && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                     )}
                    </div>
                    </div>


           

           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={close_popup} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedata}>
            Save
          </button>
        </DialogActions>
      </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

    </DashboardLayout>
  );
}

export default TeamWFH;