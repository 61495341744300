import React, { useState, useEffect, useRef } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate, useParams } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function Guest_client_details() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [clientMobile, setClientMobile] = useState("");
  const [clientName, setClientName] = useState('');
  const [clientBusinessName, setClientBusinessName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientCity, setClientCity] = useState('');

  const [clientAddress, setClientAddress] = useState();
  const [clientInfo, setClientInfo] = useState('');
  const [checkedMessage, setCheckedMessage] = useState('');
  const [isDivShow, setDivShow] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [checkexists, setcheckexists] = useState();

  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState();

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [selectedCallStatus, setCallStatus] = useState('');

  const [followup, setFollowup] = useState([]);

  const [selectedFollowupStatus, setFollowupStatus] = useState('');

  const [nextDays, setNextDays] = useState(0);

  const [nextFollowupDate, setNextFollowupDate] = useState('');
  const [remarks, setRemarks] = useState('');
  //const [amount,setClientAmount] = useState('');

  const [clientDetails, setClientDetails] = useState([]);

  const myDate = new Date(nextFollowupDate);
  //const nxtFolDate= myDate.toLocaleString();

  const [selectedDateFrom,setDateFrom] = useState('');
  const [selectedDateTo,setDateTo] = useState('');
  const [error, setError] = useState(null);

  const year = myDate.getFullYear();
  const month = String(myDate.getMonth() + 1).padStart(2, '0');
  const day = String(myDate.getDate()).padStart(2, '0');
  const nxtFolDate = `${year}-${month}-${day}`;

  const [follwoupModal, setFollowupModal] = useState(false);
  const [selectedFollowCallStatus, setFollCallStatus] = useState('');
  const [selectedFollowStatus, setFollStatus] = useState('');
  const [followupRemarks, setFollowupRemarks] = useState('');
  //const [followupAmount,setFollowupAmount] = useState('');
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  const [nextDays1, setNextDays1] = useState(0);

  //const myDate1 = new Date(nxtFollowupDate);
  //const nxtFolDate1 = myDate.toLocaleString();

  const year1 = myDate.getFullYear();
  const month1 = String(myDate.getMonth() + 1).padStart(2, '0');
  const day1 = String(myDate.getDate()).padStart(2, '0');
  const nxtFolDate1 = `${year1}-${month1}-${day1}`;

  const [selectedClientId, setClientId] = useState();

  const [prePackageDetails, setPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const { details } = useParams();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage,setPostPerPage] = useState(10);
  const [selectedMobileNo, setClientMobileNumber] = useState('');
  const [selectedCompanyName, setClientCompanyName] = useState('');

  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const isInitialMount = useRef(true);

  const [editModal, setEditModal] = useState(false);
  const closeUpdateControl = () => {
    setEditModal(false);
  }
  const [editClientId, setEditClientId] = useState('');

  const todayDate = new Date();
  const options = { timeZone: 'Asia/Kolkata' };
  const currentDate = new Intl.DateTimeFormat('en-CA', options).format(todayDate);

  
  const updateClientDetails = async () =>
  {
    const errors = {};
    const formData = new FormData();
    formData.append('mobile', clientMobile);
    formData.append('name', clientName);
    formData.append('business_name', clientBusinessName);
    formData.append('email', clientEmail);
    formData.append('group', selectedCity);
    formData.append('city', clientCity);
    formData.append('address', clientAddress);
    formData.append('product_id', selectedProduct);
    formData.append('service_id', selectedService);
    formData.append('category_id', selectedCategory);
    formData.append('editClientId', editClientId);
    formData.append('created_by', emp_id);

    if (!clientName) {
      errors.clientName = 'Name is required';
    }
    if (!clientBusinessName) {
      errors.clientBusinessName = 'Business Name is required';
    }

    if (!clientMobile) {
      errors.clientMobile = 'Mobile Number is required';
    }

    if (!selectedCity) {
      errors.selectedCity = 'Client Group is required';
    }

    if (!clientCity) {
      errors.clientCity = 'Client city is required';
    }

    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }

    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }

    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.post('update-guest-client-details', formData);
      if (response.data.status === 200) {
        setEditModal(false);
        getClientDetails();
        resetFollowupFields();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else {
        setEditModal(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    }
    catch (error) {
      setEditModal(false);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
  }

  const editOpenModal = async (id) => {
    setLoading(true);
    setEditClientId(id);
    try {
      const response = await dashboardApi.get('get-guest-client-details', {
        params: {
          emp_id: emp_id,
          id: id,
        }
      });
      if (response.data.status === 200) {
        // setDivShow(true);
        setEditModal(true);
        setClientMobile(response.data.data.mobile_no);
        setcheckexists('No');
        setClientName(response.data.data.name);
        setClientBusinessName(response.data.data.business_name)
        setClientEmail(response.data.data.email_id)
        setSelectedCity(response.data.data.group_id)
        setClientCity(response.data.data.city)
        setClientAddress(response.data.data.address)
        setSelectedProduct(response.data.data.product_id)
        setSelectedService(response.data.data.service_id)
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    }
    catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
  }





  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const resetFollowupFields = () => {
    setClientMobile('');
    setClientName('');
    setClientBusinessName('');
    setClientEmail('');
    setClientAddress('');
    setSelectedProduct('');
    setSelectedCity('');
    setSelectedService('');
    setSelectedCategory('');
    setCallStatus('');
    setFollowupStatus('');
    setFollowupStatus('');
    setNextDays('');
    setRemarks('');
    setFollCallStatus('');
    setFollStatus('');
    setFollowupRemarks('');
    setNxtFollowupDate('');
    setSelectedPackage('');
  }


  const followupOpenControl = (id) => {
    setClientId(id);
    setFollowupModal(true);
  }

  const handlePageClick = (data) => {
    console.log("Pagination clicked, new page:", data.selected + 1);
    setCurrentPage(data.selected); 
  };

  const closeFollowupControl = () => {
    setFollowupModal(false);
    resetFollowupFields();
  }


  

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const formData = new FormData();
        formData.append('emp_id', emp_id);
        formData.append('id', id);

        try {
          const response = await dashboardApi.post('update-business-lead-data', formData);
          if (response.data.status === 200) {
            // setDivShow(true);
            setClientMobile(response.data.data.mobile_no);
            setcheckexists(response.data.exists);
            setClientName(response.data.data.name);
            setClientBusinessName(response.data.data.business_name)
            setClientEmail(response.data.data.email_id)
            setSelectedCity(response.data.data.group_id)
            setClientCity(response.data.data.city)
            setClientAddress(response.data.data.address)
            setSelectedProduct(response.data.data.product_id)
            setSelectedService(response.data.data.service_id)
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        }
        catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        }
      };

      if (details === 'business_lead') {
        setAddModal(true);
        fetchData();
      }
    }
  }, [details, emp_id, id]);



  const getClientDetails = async (filters = {}) =>
  {
    
   setLoading(true);
   try
    {
      const {
        empId = emp_id,
        emp_id: selectedEmp = '',
        mobileNo: selectedMobileNo = '',
        companyName: selectedCompanyName = '',
        product:selectedProduct = '',
        group:selectedCity = '',
        dateFrom = '',
        dateTo = '',
        per_page: postPerPage = '10',
        page: currentPage,
      } = filters;
  
      
      const response = await dashboardApi.get(`get-client-details`, {
        params: {
          empId, 
          emp_id: selectedEmp,
          mobileNo: selectedMobileNo,
          companyName: selectedCompanyName,
          product:selectedProduct,
          group:selectedCity,
          per_page: postPerPage,
          page: currentPage,
          dateFrom,
          dateTo,
        },
      });
  
      
      if(response.data.status === 200)
      {
        const responseData = response.data.data;
        const clientData = responseData.data || responseData;
        setNumber(responseData.last_page);
        setClientDetails(clientData);
      }
      else
      {
        console.error('Error fetching client details:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client details:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
  
    if (isInitialMount.current)
    {
      isInitialMount.current = false;
      return;
    }
    const dateFrom = new Date(selectedDateFrom);
    const dateTo = new Date(selectedDateTo);

    if (selectedDateFrom)
    {
      if (!selectedDateTo)
      {
        setError("Date To should not be empty.");
        return;
      }
    
      const dateFrom = new Date(selectedDateFrom);
      const dateTo = new Date(selectedDateTo);
    
      if (dateFrom > dateTo)
      {
        setError("Date From should not be later than Date To.");
        return;
      }
    }

    const filters = {
      empId: emp_id,
      emp_id: selectedEmp,
      mobileNo: selectedMobileNo,
      companyName: selectedCompanyName,
      product:selectedProduct,
      group:selectedCity,
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
      per_page: postPerPage,
      page: currentPage,
    };
  
    getClientDetails(filters);
  }, [selectedEmp, selectedMobileNo, selectedCompanyName, selectedCity, selectedProduct,  postPerPage, selectedDateFrom, selectedDateTo , currentPage]);
  

  const submitFollowupForm = async () => 
  {
    const errors = {};
    if (Object.keys(errors).length > 0)
    {
      return;
    }
    const formData = new FormData();
    formData.append('client_id', selectedClientId);
    formData.append('disposition', selectedFollowCallStatus);
    formData.append('followup_status', selectedFollowStatus);
    formData.append('next_followup_date', nxtFolDate1);
    formData.append('remarks', followupRemarks);
    formData.append('created_by', emp_id);

    try {
      const response = await dashboardApi.post('add-client-followup', formData);
      if (response.data.status === 200) {
        resetFollowupFields();
        closeModalOpenControl();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else
      {
        closeModalOpenControl();
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    }
    catch (error)
    {
      closeModalOpenControl();
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const submitForm = async () =>
  {
    const errors = {};
    const formData = new FormData();
    formData.append('mobile', clientMobile);
    formData.append('name', clientName);
    formData.append('business_name', clientBusinessName);
    formData.append('email', clientEmail);
    formData.append('group', selectedCity);
    formData.append('city', clientCity);
    formData.append('address', clientAddress);
    formData.append('product_id', selectedProduct);
    formData.append('service_id', selectedService);
    formData.append('category_id', selectedCategory);
    formData.append('disposition', selectedCallStatus);
    formData.append('followup_status', selectedFollowupStatus);
    formData.append('next_followup_date', nxtFolDate);
    formData.append('remarks', remarks);
    formData.append('created_by', emp_id);
    formData.append('prePackageId', selectedPackage);
    if (!clientName) {
      errors.clientName = 'Name is required';
    }
    if (!clientBusinessName) {
      errors.clientBusinessName = 'Business Name is required';
    }

    if (!clientMobile) {
      errors.clientMobile = 'Mobile Number is required';
    }

    if (!selectedCity) {
      errors.selectedCity = 'Client Group is required';
    }

    if (!clientCity) {
      errors.clientCity = 'Client city is required';
    }

    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }

    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }

    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }


    if (!selectedCallStatus) {
      errors.selectedCallStatus = 'Call status is required';
    }

    if (!selectedFollowupStatus) {
      errors.selectedFollowupStatus = 'Followup status is required';
    }

    if (!nextFollowupDate && selectedCallStatus == 'Connected') {
      errors.nextFollowupDate = 'Next followup date is required';
    }


    if (!remarks) {
      errors.remarks = 'Remarks is required';
    }

    if (selectedFollowupStatus == 5 | selectedFollowupStatus == 6 | selectedFollowupStatus == 7 | selectedFollowupStatus == 9) {
      if (!selectedPackage) {
        errors.selectedPackage = 'Package is required';
      }
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.post('add-guest-client', formData);
      if (response.data.status === 200) {
        closeModalOpenControl(false);
        resetFollowupFields();
        getClientDetails();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else {
        closeModalOpenControl(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'The email must be a valid email address or check required field.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    checkClientsDetails();
    getCityDetails();
    getProduct();
    getClientDetails();
    getExeDetails();
  }, [])

  const addModalOpenControl = () => {
    setAddModal(true);
  }

  const closeModalOpenControl = () => {
    setAddModal(false);
  }

  const checkClientsDetails = async () => {
    const errors = {};

    if (!clientMobile) {
      errors.chkmobileNo = 'Mobile no in required.';
    }
    if (clientMobile.length != 10) {
      errors.chkmobileNo = 'Invalied mobile no.';
    }
    
    setValidationErrors(errors);
    if(Object.keys(errors).length > 0)
    {
      return;
    }

    if (clientMobile)
    {
      try {
        const response = await dashboardApi.get(`check-client-mobile-number/${clientMobile}`);
        if (response.data.status === 200) {
          setDivShow(false);
          setCheckedMessage(response.data.message);
          setClientInfo(response.data.data);
        }
        else {
          setDivShow(true);
          setClientInfo('');
          setCheckedMessage('');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }
  }

  function checkMobileNumber()
  {
    checkClientsDetails();
  }

  const checkInput = (e) => {
    const number = e.target.value.replace(/\D/g, "");
    setClientMobile(number);
  };

  const getCityDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-city-details`);
      if (response.data.status === 200) {
        setCityList(response.data.data);
      }
      else {
        console.log('Data not found');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const getProduct = async () =>
  {
    try {
      const response = await dashboardApi.get(`sales-product-list/active`);
      if (response.data.status === 200) {
        setProduct(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedCity && selectedService) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-pre-package-list/${selectedProduct}/${selectedCity}/${selectedService}`);
          if (response.data.status === 200) {
            setPackageList(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  }, [selectedCity, selectedCity, selectedService]);

  useEffect(() => {
    if (selectedService) {
      const fetchCategory = async () => {
        try {
          const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
          if (response.data.status === 200) {
            setCategory(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchCategory();
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedCallStatus) {


      const getFollowupStatus = async () => {
        try {
          const response = await dashboardApi.get(`get-followup-list/${selectedCallStatus}`);
          if (response.data.status === 200) {
            setFollowup(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getFollowupStatus();
    }
  }, [selectedCallStatus]);

  useEffect(() => {
    if (selectedFollowCallStatus) {


      const getFollowupStatus = async () => {
        try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200) {
            setFollowup(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getFollowupStatus();
    }
  }, [selectedFollowCallStatus]);


  useEffect(() => {
    if (selectedFollowupStatus) {
      const getNextFollowupDate = async () => {
        try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowupStatus}`);
          if (response.data.status === 200) {
            setNextFollowupDate('');
            setNextDays(response.data.data);

          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getNextFollowupDate();
    }
  }, [selectedFollowupStatus]);

  useEffect(() => {
    if (selectedFollowStatus) {
      const getNextFollowupDate = async () => {
        try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
          if (response.data.status === 200) {
            setNextFollowupDate('');
            setNextDays1(response.data.data);

          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getNextFollowupDate();
    }
  }, [selectedFollowStatus]);


  const updateBusinessLeadData = async () =>
  {
    const errors = {};
    const formData = new FormData();
    formData.append('mobile', clientMobile);
    formData.append('name', clientName);
    formData.append('business_name', clientBusinessName);
    formData.append('email', clientEmail);
    formData.append('group', selectedCity);
    formData.append('city', clientCity);
    formData.append('address', clientAddress);
    formData.append('product_id', selectedProduct);
    formData.append('service_id', selectedService);
    formData.append('category_id', selectedCategory);
    formData.append('disposition', selectedCallStatus);
    formData.append('followup_status', selectedFollowupStatus);
    formData.append('next_followup_date', nxtFolDate);
    formData.append('remarks', remarks);
    formData.append('created_by', emp_id);
    formData.append('prePackageId', selectedPackage);
    formData.append('id', id);
    
    if (!clientName)
    {
      errors.clientName = 'Name is required';
    }
    if(!clientBusinessName)
    {
      errors.clientBusinessName = 'Business Name is required';
    }

    if(!clientMobile)
    {
      errors.clientMobile = 'Mobile Number is required';
    }

    if (!selectedCity) {
      errors.selectedCity = 'Client Group is required';
    }

    if (!clientCity) {
      errors.clientCity = 'Client city is required';
    }

    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }

    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }

    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }


    if (!selectedCallStatus) {
      errors.selectedCallStatus = 'Call status is required';
    }

    if (!selectedFollowupStatus) {
      errors.selectedFollowupStatus = 'Followup status is required';
    }

    if (!nextFollowupDate && selectedCallStatus == 'Connected') {
      errors.nextFollowupDate = 'Next followup date is required';
    }


    if (!remarks) {
      errors.remarks = 'Remarks is required';
    }

    if (selectedFollowupStatus == 5 && selectedFollowupStatus == 6 && selectedFollowupStatus == 7 && selectedFollowupStatus == 9) {
      errors.selectedPackage = 'Package is required';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.post('update-guest-client-details', formData);
      if (response.data.status === 200) {

        closeModalOpenControl();
        resetFollowupFields();
        navigate('/guest-client-details');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card className='p-2' >
          <div className="d-flex justify-content-between align-items-center">
            <ClientDetailsHeader></ClientDetailsHeader>
          </div>
         
          <div className='d-flex flex-wrap px-sm-0 px-2'>

            <div className='col-sm-1 d-flex my-2 flex-column  px-sm-2 px-1'>
              <small className='m-0'>Length</small>
              <select className="form-control"
                value={postPerPage}
                onChange={(e) => setPostPerPage(e.target.value)}
              >
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='200'>200</option>
                <option value='500'>500</option>
              </select>
            </div>

            <div className='col-sm-3 d-flex my-2 flex-column px-sm-2 px-1'>
              <small className='m-0'>Date From</small>
              <input
                type='date'
                className='form-control'
                value={selectedDateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>

            <div className='col-sm-3 d-flex my-2 flex-column px-sm-2 px-1'>
              <small className='m-0'>Date To</small>
              <input
                type='date'
                className='form-control'
                value={selectedDateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
              {error && <small className='text-danger'>{error}</small>}
            </div>
                  

                    
                  

            <div className='col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1'>
              <small className='m-0'>Mobile No</small>
              <input type="text" className='form-control' value={selectedMobileNo} onChange={(e) => { setClientMobileNumber(e.target.value) }} />
            </div>

            <div className='col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1'>
              <small className='m-0'>Company Name</small>
              <input type="text" className='form-control' value={selectedCompanyName} onChange={(e) => { setClientCompanyName(e.target.value) }} />
            </div>

            <div className='col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1'>
              <small className='m-0'>Select Eexecutive</small>
              <select className="form-control"
                value={selectedEmp}
                onChange={(e) => setSelectedEmp(e.target.value)}
              >
                <option value=''>Select Eexecutive</option>
                {exeDetails.map((ed) => (
                  <option key={ed.emp_id} value={ed.emp_id}>
                    {ed.emp_fname} {ed.emp_lname}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1">
              <small>Salect Product</small>
              <select className="form-control" value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}>
                <option value=''>Select Product</option>
                {product.map((pro) => (
                  <option key={pro.id} value={pro.id}>
                    {pro.product_name}
                  </option>
                ))}
              </select>
              {validationErrors.selectedProduct && (
                <small className="text-danger d-block">{validationErrors.selectedProduct}</small>
              )}
            </div>

            <div class="col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1">
              <small>Select Group</small>
              <select className="form-control"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <option value=''>Select City</option>
                {cityList.map((city) => (
                  <option key={city.group_id} value={city.group_id}>
                    {city.name}
                  </option>
                ))}
              </select>
              
            </div>

          </div>

          <div className='d-flex justify-content-between p-2'>
            <strong>Guest List</strong>
            <Link className='btn btn-info ml-2' onClick={addModalOpenControl}>
              Add New +
            </Link>
          </div>
          {loading && !editClientId ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
            </p>
          ) : (
            <div className='mx-2' style={{ overflowY: "hidden" }}>

              <table className="table table-bordered table-hovered">

                <tr>
                  <td>S.No</td>
                  <td>Client Info</td>
                  <td>Mobile No</td>
                  <td>Email ID</td>
                  <td>Product</td>
                  <td>Service</td>
                  <td>Group Name</td>
                  <td>Exe Name</td>
                  <td>Status</td>
                  <td>Created Date</td>
                  <td>Action</td>
                </tr>

                {clientDetails && clientDetails.map((cd, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{cd.name} <br />{cd.business_name}</td>
                    <td>{cd.mobile_no} </td> 
                    <td>{cd.email_id}</td>
                    <td>{cd.product_name}</td>
                    <td>{cd.service_name}</td>
                    <td>{cd.group_name}</td>
                    <td>{cd.emp_name}</td>
                    
                    <td>
                      {cd.status == 0 ? <span style={{ color: 'green' }}>New</span> 
                      :(cd.status == 1 && (currentDate < cd.next_followup_date))?  <span style={{ color: 'red' }}>In Followup</span>:''
                    }
                    </td>
                    <td>{cd.created_at}</td>

                    <td className="d-flex flex-column align-items-center">
                      <Link className="btn btn-primary float-end d-flex my-1" to={`/client-profile/${cd.id}`}>
                        <span class="material-icons-outlined" alt='Profile'>manage_accounts</span>
                      </Link>
                      {loading && editClientId == cd.id ? (
                        <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '70px', width: '100px' }}></img>
                      ) : (
                        <span className="material-icons-outlined btn btn-warning  float-end my-1" onClick={() => { editOpenModal(cd.id) }}>
                          edit_note
                        </span>
                      )}
                    </td>

                  </tr>
                ))
                }

              </table>
              <Pagination className="custom-pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={number}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link-style"}
                  breakLinkClassName={"break-link-style"}
                  previousLinkClassName={"prev-link-style"}
                  nextLinkClassName={"next-link-style"}
                  forcePage={currentPage}
                />
              </Pagination>
            </div>
          )}

        </Card>
      </MDBox>
      <Footer />



      <Dialog className='col-12 p-sm-4 p-sm-3 p-2 mx-auto' open={addModal}>
        <DialogTitle>Add Client Details</DialogTitle>
        <DialogContent style={{ margin: '0' }}>
          <form>
            <div className="d-flex flex-wrap align-items-end justify-content-between">
              <div className="col-sm-6 px-sm-2 px-1">
                <small>Check Mobile No</small>
                <input type="text" maxLength={10} className='form-control' value={clientMobile} onChange={(e) => checkInput(e)} ></input>
                {!details && validationErrors.chkmobileNo && (
                  <small className="text-danger d-block">{validationErrors.chkmobileNo}</small>
                )}
                {
                  checkexists == 'Yes' && details &&
                  <small className="text-danger d-block">This Number Is Match With Another Record</small>
                }
              </div>


              {!details &&
                <div className="px-sm-2 px-1">
                  <Button className='mt-sm-4' style={{float: 'right' }} onClick={checkMobileNumber}>Check Client Details</Button>
                </div>
              }

              <div className='col-sm-12 p-1'>
                <p className='text-danger m-0'><br />{checkedMessage} </p>
                {clientInfo && clientInfo.length > 0 && (
                  <>
                    {clientInfo.map((client, index) => (
                      <div className="d-flex flex-wrap" key={index}>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Client Name</strong> <small> {client.client_name}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Company Name</strong> <small> {client.business_name || 'N/A'}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Executive Name</strong> <small> {client.emp_name || 'N/A'}</small></div>
                        <div className="col-sm-3 d-flex flex-column px-0"> <strong className='text-success'>Status</strong> <small> {client.status == 1 ? 'Verified' : client.status == 2 ? 'Active' : client.status == 3 ? 'Inactive' : client.status == 4 ? 'expire' : client.status == 5 ? 'Blacklist' : client.status == 6 ? 'isDeleted' : 'Pending'}</small></div>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />

                <p style={{ margin: "0 10px" }}>Basic Details</p>

                <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
              </div>
            </div>


            {isDivShow && (
              <div className="d-flex flex-wrap">
                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Client Name</small>
                  <input type="text" className='form-control' value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                  {validationErrors.clientName && (
                    <small className="text-danger d-block">{validationErrors.clientName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Business Name</small>
                  <input type="text" className='form-control' value={clientBusinessName} onChange={(e) => setClientBusinessName(e.target.value)}></input>
                  {validationErrors.clientBusinessName && (
                    <small className="text-danger d-block">{validationErrors.clientBusinessName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Mobile Number</small>
                  <input type="text" className='form-control' value={clientMobile} readOnly></input>
                  {validationErrors.clientMobile && (
                    <small className="text-danger d-block">{validationErrors.clientMobile}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Email Id</small>
                  <input type="text" className='form-control' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)}></input>
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Select Group</small>
                  <select className="form-control"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value=''>Select City</option>
                    {cityList.map((city) => (
                      <option key={city.group_id} value={city.group_id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedCity && (
                    <small className="text-danger d-block">{validationErrors.selectedCity}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>City</small>
                  <input type="text" className='form-control' value={clientCity} onChange={(e) => setClientCity(e.target.value)}></input>
                  {validationErrors.clientCity && (
                    <small className="text-danger d-block">{validationErrors.clientCity}</small>
                  )}
                </div>

                <div class="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Address</small>
                  <input type="text" className='form-control' value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}></input>
                  {validationErrors.clientAddress && (
                    <small className="text-danger d-block">{validationErrors.clientAddress}</small>
                  )}
                </div>

                <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                  <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                  <p style={{ margin: "0 10px" }}>Product Details</p>
                  <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                </div>


                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Salect Product</small>
                  <select className="form-control" value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}>
                    <option value=''>Select Product</option>
                    {product.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <small className="text-danger d-block">{validationErrors.selectedProduct}</small>
                  )}
                </div>
                {selectedProduct && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Service</small>
                    <select className="form-control"
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.target.value)}
                    >
                      <option value=''>Select Service</option>
                      {service.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedService && (
                      <small className="text-danger d-block">{validationErrors.selectedService}</small>
                    )}
                  </div>
                )}

                {category != '' && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Category</small>
                    <select className="form-control"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value=''>Select Category</option>
                      {category.map((cate) => (
                        <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                        </option>
                      ))}
                    </select>

                  </div>
                )}

                {selectedProduct && selectedService && (


                  <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                    <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Followup Details</p>
                    <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                  </div>

                )}

                {selectedProduct && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Call Status</small>
                    <select className="form-control"
                      value={selectedCallStatus}
                      onChange={(e) => setCallStatus(e.target.value)}
                    >
                      <option value=''>Select Call Status</option>
                      <option value='Connected'>Connected</option>
                      <option value='Not_Connected'>Not Connected</option>
                    </select>
                    {validationErrors.selectedCallStatus && (
                      <small className="text-danger d-block">{validationErrors.selectedCallStatus}</small>
                    )}
                  </div>
                )}

                {selectedCallStatus && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Followup Status</small>
                    <select className="form-control"
                      value={selectedFollowupStatus}
                      onChange={(e) => setFollowupStatus(e.target.value)}
                    >
                      <option value=''>Select Followup Status</option>
                      {followup.map((follo) => (
                        <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedFollowupStatus && (
                      <small className="text-danger d-block" >{validationErrors.selectedFollowupStatus}</small>
                    )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus == 5 || selectedFollowupStatus == 6 || selectedFollowupStatus == 7 || selectedFollowupStatus == 9) && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Package</small>
                    <select className="form-control"
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                    >
                      <option value=''>Select Package Details</option>
                      {prePackageDetails.map((prePkg) => (
                        <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedPackage && (
                      <small className="text-danger d-block">{validationErrors.selectedPackage}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && selectedCallStatus != 'Not_Connected' && selectedFollowupStatus != 15 && (

                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Next Followup Date</small>
                    <DatePicker className="form-control"
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                      selected={nextFollowupDate}
                      onChange={(date) => setNextFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    {validationErrors.nextFollowupDate && (
                      <small className="text-danger d-block">{validationErrors.nextFollowupDate}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && (
                  <div className="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Remarks</small>
                    <input type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></input>
                    {validationErrors.remarks && (
                      <small className="text-danger d-block">{validationErrors.remarks}</small>
                    )}
                  </div>
                )}
              </div>
            )}

            {checkexists == 'No' && (
              <div className="d-flex flex-wrap">
                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Client Name</small>
                  <input type="text" className='form-control' value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                  {validationErrors.clientName && (
                    <small className="text-danger d-block">{validationErrors.clientName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Business Name</small>
                  <input type="text" className='form-control' value={clientBusinessName} onChange={(e) => setClientBusinessName(e.target.value)}></input>
                  {validationErrors.clientBusinessName && (
                    <small className="text-danger d-block">{validationErrors.clientBusinessName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Mobile Number</small>
                  <input type="text" className='form-control' value={clientMobile} readOnly></input>
                  {validationErrors.clientMobile && (
                    <small className="text-danger d-block" >{validationErrors.clientMobile}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Email Id</small>
                  <input type="text" className='form-control' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)}></input>

                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Select Group</small>
                  <select className="form-control"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value=''>Select City</option>
                    {cityList.map((city) => (
                      <option key={city.group_id} value={city.group_id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedCity && (
                    <small className="text-danger d-block">{validationErrors.selectedCity}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>City</small>
                  <input type="text" className='form-control' value={clientCity} onChange={(e) => setClientCity(e.target.value)}></input>
                  {validationErrors.clientCity && (
                    <small className="text-danger d-block">{validationErrors.clientCity}</small>
                  )}
                </div>

                <div class="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Address</small>
                  <input type="text" className='form-control' value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}></input>
                  {validationErrors.clientAddress && (
                    <small className="text-danger d-block">{validationErrors.clientAddress}</small>
                  )}
                </div>

                <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                  <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                  <p style={{ margin: "0 10px" }}>Product Details</p>
                  <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                </div>

                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Salect Product</small>
                  <select className="form-control" value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}>
                    <option value=''>Select Product</option>
                    {product.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <small className="text-danger d-block">{validationErrors.selectedProduct}</small>
                  )}
                </div>
                {selectedProduct && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Service</small>
                    <select className="form-control"
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.target.value)}
                    >
                      <option value=''>Select Service</option>
                      {service.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedService && (
                      <small className="text-danger d-block">{validationErrors.selectedService}</small>
                    )}
                  </div>
                )}

                {category != '' && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Category</small>
                    <select className="form-control"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value=''>Select Category</option>
                      {category.map((cate) => (
                        <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                        </option>
                      ))}
                    </select>

                  </div>
                )}

                {selectedProduct && selectedService && (

                  <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                    <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Followup Details</p>
                    <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                  </div>

                )}

                {selectedProduct && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Call Status</small>
                    <select className="form-control"
                      value={selectedCallStatus}
                      onChange={(e) => setCallStatus(e.target.value)}
                    >
                      <option value=''>Select Call Status</option>
                      <option value='Connected'>Connected</option>
                      <option value='Not_Connected'>Not Connected</option>
                    </select>
                    {validationErrors.selectedCallStatus && (
                      <small className="text-danger d-block">{validationErrors.selectedCallStatus}</small>
                    )}
                  </div>
                )}

                {selectedCallStatus && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Followup Status</small>
                    <select className="form-control"
                      value={selectedFollowupStatus}
                      onChange={(e) => setFollowupStatus(e.target.value)}
                    >
                      <option value=''>Select Followup Status</option>
                      {followup.map((follo) => (
                        <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedFollowupStatus && (
                      <small className="text-danger d-block">{validationErrors.selectedFollowupStatus}</small>
                    )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus == 5 || selectedFollowupStatus == 6 || selectedFollowupStatus == 7 || selectedFollowupStatus == 9) && (
                  <div className="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Package</small>
                    <select className="form-control"
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                    >
                      <option value=''>Select Package Details</option>
                      {prePackageDetails.map((prePkg) => (
                        <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedPackage && (
                      <small className="text-danger d-block">{validationErrors.selectedPackage}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && selectedCallStatus != 'Not_Connected' && selectedFollowupStatus != 15 && (

                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Next Followup Date</small>
                    <DatePicker className="form-control"
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                      selected={nextFollowupDate}
                      onChange={(date) => setNextFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    {validationErrors.nextFollowupDate && (
                      <small className="text-danger d-block">{validationErrors.nextFollowupDate}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Remarks</small>
                    <input type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></input>
                    {validationErrors.remarks && (
                      <small className="text-danger d-block">{validationErrors.remarks}</small>
                    )}
                  </div>
                )}
              </div>
            )}
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeModalOpenControl}>Close</Link>
          {isDivShow && (
            <Link className="btn btn-success btn-sm" onClick={submitForm}>Add Details</Link>
          )}
          {
            checkexists == 'No' && details && (
              <Link className="btn btn-success btn-sm" onClick={updateBusinessLeadData}>Update</Link>
            )}
        </DialogActions>
      </Dialog>

      <Dialog className='col-12 p-sm-4 p-3 mx-auto' open={editModal}>
        <DialogTitle>Update Client Details</DialogTitle>
        <DialogContent style={{ margin: '0' }}>
          <form>
            <div className="d-flex flex-wrap">
              <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                <small>Check Mobile No</small>
                <input type="text" maxLength={10} className='form-control' value={clientMobile} onChange={(e) => checkInput(e)} ></input>
                <small className="text-danger d-block">{clientInfo}</small>

                {!details && validationErrors.chkmobileNo && (
                  <small className="text-danger d-block">{validationErrors.chkmobileNo}</small>
                )}
              </div>

              {!details &&
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <Button  className='mt-sm-4' style={{float: 'right' }} onClick={checkMobileNumber}>Check Client Details</Button>
                </div>
              }

              <div className="col-sm-12 d-flex align-items-center my-3 p-1">
                <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
                <p style={{ margin: "0 10px" }}>Basic Details</p>
                <div style={{ flex: 1, backgroundColor: "#1770b8", height: "3px" }} />
              </div>

            </div>


            {isDivShow && (
              <div className="d-flex flex-wrap">
                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Client Name</small>
                  <input type="text" className='form-control' value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                  {validationErrors.clientName && (
                    <small className="text-danger d-block">{validationErrors.clientName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Business Name</small>
                  <input type="text" className='form-control' value={clientBusinessName} onChange={(e) => setClientBusinessName(e.target.value)}></input>
                  {validationErrors.clientBusinessName && (
                    <small className="text-danger d-block">{validationErrors.clientBusinessName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Mobile Number</small>
                  <input type="text" className='form-control' value={clientMobile} readOnly></input>
                  {validationErrors.clientMobile && (
                    <small className="text-danger d-block">{validationErrors.clientMobile}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Email Id</small>
                  <input type="text" className='form-control' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)}></input>
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Select Group</small>
                  <select className="form-control"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value=''>Select City</option>
                    {cityList.map((city) => (
                      <option key={city.group_id} value={city.group_id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedCity && (
                    <small className="text-danger d-block">{validationErrors.selectedCity}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>City</small>
                  <input type="text" className='form-control' value={clientCity} onChange={(e) => setClientCity(e.target.value)}></input>
                  {validationErrors.clientCity && (
                    <small className="text-danger d-block">{validationErrors.clientCity}</small>
                  )}
                </div>

                <div class="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Address</small>
                  <input type="text" className='form-control' value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}></input>
                  {validationErrors.clientAddress && (
                    <small className="text-danger d-block">{validationErrors.clientAddress}</small>
                  )}
                </div>

                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  <p style={{ margin: "0 10px" }}>Product Details</p>
                  <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>

                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Salect Product</small>
                  <select className="form-control" value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}>
                    <option value=''>Select Product</option>
                    {product.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <small className="text-danger d-block">{validationErrors.selectedProduct}</small>
                  )}
                </div>
                {selectedProduct && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Service</small>
                    <select className="form-control"
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.target.value)}
                    >
                      <option value=''>Select Service</option>
                      {service.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedService && (
                      <small className="text-danger d-block">{validationErrors.selectedService}</small>
                    )}
                  </div>
                )}

                {category != '' && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Category</small>
                    <select className="form-control"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value=''>Select Category</option>
                      {category.map((cate) => (
                        <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {selectedProduct && selectedService && (

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />

                    <p style={{ margin: "0 10px" }}>Followup Details</p>

                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                )}

                {selectedProduct && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Call Status</small>
                    <select className="form-control"
                      value={selectedCallStatus}
                      onChange={(e) => setCallStatus(e.target.value)}
                    >
                      <option value=''>Select Call Status</option>
                      <option value='Connected'>Connected</option>
                      <option value='Not_Connected'>Not Connected</option>
                    </select>
                    {validationErrors.selectedCallStatus && (
                      <small className="text-danger d-block">{validationErrors.selectedCallStatus}</small>
                    )}
                  </div>
                )}

                {selectedCallStatus && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Followup Status</small>
                    <select className="form-control"
                      value={selectedFollowupStatus}
                      onChange={(e) => setFollowupStatus(e.target.value)}
                    >
                      <option value=''>Select Followup Status</option>
                      {followup.map((follo) => (
                        <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedFollowupStatus && (
                      <small className="text-danger d-block">{validationErrors.selectedFollowupStatus}</small>
                    )}
                  </div>
                )}

                {selectedCity && selectedProduct && selectedService && (selectedFollowupStatus == 5 || selectedFollowupStatus == 6 || selectedFollowupStatus == 7 || selectedFollowupStatus == 9) && (
                  <div className="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Package</small>
                    <select className="form-control"
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                    >
                      <option value=''>Select Package Details</option>
                      {prePackageDetails.map((prePkg) => (
                        <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedPackage && (
                      <small className="text-danger d-block">{validationErrors.selectedPackage}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && selectedCallStatus != 'Not_Connected' && selectedFollowupStatus != 15 && (

                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Next Followup Date</small>
                    <DatePicker className="form-control"
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                      selected={nextFollowupDate}
                      onChange={(date) => setNextFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    {validationErrors.nextFollowupDate && (
                      <small className="text-danger d-block">{validationErrors.nextFollowupDate}</small>
                    )}
                  </div>
                )}

                {selectedFollowupStatus && (
                  <div className="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Remarks</small>
                    <input type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></input>
                    {validationErrors.remarks && (
                      <small className="text-danger d-block">{validationErrors.remarks}</small>
                    )}
                  </div>
                )}
              </div>
            )}

            {checkexists == 'No' && (
              <div className="d-flex flex-wrap">
                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Client Name</small>
                  <input type="text" className='form-control' value={clientName} onChange={(e) => setClientName(e.target.value)}></input>
                  {validationErrors.clientName && (
                    <small className="text-danger d-block">{validationErrors.clientName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Business Name</small>
                  <input type="text" className='form-control' value={clientBusinessName} onChange={(e) => setClientBusinessName(e.target.value)}></input>
                  {validationErrors.clientBusinessName && (
                    <small className="text-danger d-block">{validationErrors.clientBusinessName}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Mobile Number</small>
                  <input type="text" className='form-control' value={clientMobile} readOnly></input>
                  {validationErrors.clientMobile && (
                    <small className="text-danger d-block">{validationErrors.clientMobile}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Email Id</small>
                  <input type="text" className='form-control' value={clientEmail} onChange={(e) => setClientEmail(e.target.value)}></input>

                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Select Group</small>
                  <select className="form-control"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value=''>Select City</option>
                    {cityList.map((city) => (
                      <option key={city.group_id} value={city.group_id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedCity && (
                    <small className="text-danger d-block">{validationErrors.selectedCity}</small>
                  )}
                </div>

                <div class="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>City</small>
                  <TextField type="text" className='form-control' value={clientCity} onChange={(e) => setClientCity(e.target.value)}></TextField>
                  {validationErrors.clientCity && (
                    <small className="text-danger d-block">{validationErrors.clientCity}</small>
                  )}
                </div>

                <div class="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Address</small>
                  <TextField type="text" className='form-control' value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}></TextField>
                  {validationErrors.clientAddress && (
                    <small className="text-danger d-block">{validationErrors.clientAddress}</small>
                  )}
                </div>

                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  <p style={{ margin: "0 10px" }}>Product Details</p>
                  <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>

                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Salect Product</small>
                  <select className="form-control" value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}>
                    <option value=''>Select Product</option>
                    {product.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <small className="text-danger d-block">{validationErrors.selectedProduct}</small>
                  )}
                </div>
                {selectedProduct && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Service</small>
                    <select className="form-control"
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.target.value)}
                    >
                      <option value=''>Select Service</option>
                      {service.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.selectedService && (
                      <small className="text-danger d-block">{validationErrors.selectedService}</small>
                    )}
                  </div>
                )}

                {category != '' && selectedService && (
                  <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                    <small>Select Category</small>
                    <select className="form-control"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value=''>Select Category</option>
                      {category.map((cate) => (
                        <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                        </option>
                      ))}
                    </select>

                  </div>
                )}
              </div>
            )}
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeUpdateControl}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={updateClientDetails}>Update Details</Link>
        </DialogActions>
      </Dialog>


      {/* for already added client followup details */}

      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form>
            <div className="row">

              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "0 10px" }}>Followup Details</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                <small>Call Status</small>
                <select className="form-control"
                  value={selectedFollowCallStatus}
                  onChange={(e) => setFollCallStatus(e.target.value)}
                >
                  <option value=''>Select Call Status</option>
                  <option value='Connected'>Connected</option>
                  <option value='Not_Connected'>Not Connected</option>
                </select>
              </div>


              {selectedFollowCallStatus && (
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Followup Status</small>
                  <select className="form-control"
                    value={selectedFollowStatus}
                    onChange={(e) => setFollStatus(e.target.value)}
                  >
                    <option value=''>Select Followup Status</option>
                    {followup.map((follo) => (
                      <option key={follo.id} value={follo.id}>
                        {follo.activity_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedFollowStatus && (
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Next Followup Date</small>
                  <DatePicker className="form-control" style={{ position: 'absolute' }}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays1))}
                    selected={nxtFollowupDate}
                    onChange={(date) => setNxtFollowupDate(date)}
                    dateFormat="dd/MM/YYYY"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              )}

              {selectedFollowStatus && (
                <div className="col-12 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Remarks</small>
                  <input type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></input>
                </div>
              )}
            </div>


          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeModalOpenControl}>Close</Link>
          {isDivShow && (
            <Link className="btn btn-success btn-sm" onClick={submitForm}>Add Details</Link>
          )}
        </DialogActions>
      </Dialog>


      {/* for already added client followup details */}

      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form>
            <div className="row">

              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "0 10px" }}>Followup Details</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                <small>Call Status</small>
                <select className="form-control"
                  value={selectedFollowCallStatus}
                  onChange={(e) => setFollCallStatus(e.target.value)}
                >
                  <option value=''>Select Call Status</option>
                  <option value='Connected'>Connected</option>
                  <option value='Not_Connected'>Not Connected</option>
                </select>
              </div>


              {selectedFollowCallStatus && (
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Followup Status</small>
                  <select className="form-control"
                    value={selectedFollowStatus}
                    onChange={(e) => setFollStatus(e.target.value)}
                  >
                    <option value=''>Select Followup Status</option>
                    {followup.map((follo) => (
                      <option key={follo.id} value={follo.id}>
                        {follo.activity_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedFollowStatus && (
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Next Followup Date</small>
                  <DatePicker className="form-control" style={{ position: 'absolute' }}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays1))}
                    selected={nxtFollowupDate}
                    onChange={(date) => setNxtFollowupDate(date)}
                    dateFormat="dd/MM/YYYY"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              )}

              {selectedFollowStatus && (
                <div className="col-sm-6 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Remarks</small>
                  <input type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></input>
                </div>
              )}

            </div>

          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Add Details</Link>
          {
            checkexists == 'No' && details && (
              <Link className="btn btn-success btn-sm" onClick={updateBusinessLeadData}>Update</Link>

            )}
        </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}
export default Guest_client_details;
