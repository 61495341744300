import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from 'layouts/loaderModal/LoadingModal';
import { param } from 'jquery';

function Admin_client_details() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [mobileNo, setMobileNo] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [companyDetails, setCompanyDetails] = useState([]);

  const [clientProfile, setClientProfile] = useState({});
  const [compProfile, setCompanyProfile] = useState({});
  const [documentProfile, setDocumentProfile] = useState([]);
  const [packageProfile, setPackageProfile] = useState([]);
  const [changesHistory, setChangesHistory] = useState([]);

  
  const [stateDetails, setStateDetails] = useState([]);
  const [citiesDetails, setCitiesDetails] = useState([]);
  const [groupDetails, setGroupDetails] = useState([]);

  const [client_id, setClientId] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [profile_status, setProfileStatus] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [requestType, setRequestType] = useState("clientStatus");
  const [clientStatus, setClientStatus] = useState("");
  const [compStatus, setCompStatus] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const [packageStatus, setPackageStatus] = useState("");
  const [changesStatus, setChangesStatus] = useState("");

  const [editMode, setEditMode] = useState({});
  const [editPanMode, setEditPanMode] = useState('');
  const [updatedProfile, setUpdatedProfile] = useState('');
  const [editCompMode, setEditCompMode] = useState({});
  const [updatedCompProfile, setUpdatedCompProfile] = useState('');
  const [documentFile, setDocumentFile] = useState([]);
  //const [panCardComp,setPanChange] = useState('');
  const [isPanEdit, setPaneReadOnly] = useState(false);
  const [panCardComp, setPanCardComp] = useState({});

  const [newUploadDocumnet, setNewUploadDocumnet] = useState(false);
  const [docUpdateId, setDocUpdatedId] = useState('');
  const [newDocumentNo, setNewDocumentNumber] = useState('');
  const [newDocumentType, setNewDocumentType] = useState('');
  //const [documentsCompany,setDocumentsCompany] = useState('');
  //const [companyList, setCompanyList]=useState([]);
  const [documentListing, setDocumentListing] = useState([]);
  const [updateDocumentType, setUpdateDocumentType] = useState('');

  const handleFileChange = (e) => {
    setDocumentFile(e.target.files);
  };

  useEffect(() => {
    getStateDetails();
    getGroupList();
    getDocumentListing();
  }, []);



  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobileNo(value);
    }
  };

  const setActiveStatus = (requestType) => {
    setRequestType(requestType);
    if (requestType === "clientStatus") {
      setClientStatus("active");
      setCompStatus("");
      setDocStatus("");
      setPackageStatus("");
      setChangesStatus("");
    }
    else if (requestType === "companyStatus") {
      setClientStatus("");
      setCompStatus("active");
      setDocStatus("");
      setPackageStatus("");
      setChangesStatus("");
    }
    else if (requestType === "documentStatus") {
      setClientStatus("");
      setCompStatus("");
      setDocStatus("active");
      setPackageStatus("");
      setChangesStatus("");
    }
    else if (requestType === "packageStatus") {
      setClientStatus("");
      setCompStatus("");
      setDocStatus("");
      setPackageStatus("active");
      setChangesStatus("");
    }
    else if (requestType === "changesHistory") {
      setClientStatus("");
      setCompStatus("");
      setDocStatus("");
      setPackageStatus("");
      setChangesStatus("active");
    }
    else {
      setClientStatus("active");
      setCompStatus("");
      setDocStatus("");
      setPackageStatus("");
      setChangesStatus("");
    }
  }

  const searchCompanyDetails = async () =>
  {
    setProfileStatus(false); 
    const errors = {};
    if (!mobileNo && !businessName)
    {
      errors.searchValidation = 'At least enter a mobile number or business name.';
    }

    if (mobileNo && businessName)
    {
      errors.searchValidation = 'Enter only a mobile number or a business name, not both.';
    }

    if (mobileNo) {
      if (mobileNo.length !== 10 || isNaN(mobileNo)) {
        errors.searchValidation = 'Enter a valid 10-digit mobile number.';
      }
    }

    if (businessName) {
      if (businessName.length < 3) {
        errors.searchValidation = 'Business name must be at least 3 characters long.';
      }
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-admin-company-details`, {
        params: {
          mobileNo: mobileNo,
          businessName: businessName,
        }
      });

      if (response.data.status === 200) {
        setCompanyDetails(response.data.data);
        setIsLoading(false);
        if(response.data.data.length < 1)
        {
          showPopup('No client was found matching the provided mobile number or business name',true);
        }
      }
      else
      {
        setCompanyDetails([]);
        setProfileStatus(false);
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      setCompanyDetails([]);
      setProfileStatus(false);
      console.error('Error fetching countries:', error);
    }
  }

  const showCompanyDetails = async (compId, clientId) => {
    setClientId(clientId);
    setCompanyId(compId);
    setProfileStatus(true);
  }

  useEffect(() => {
    if (company_id && client_id) {
      showClientProfileDetails();
    }
  }, [company_id, client_id]);

  const showClientProfileDetails = async () => {
    setActiveStatus('clientStatus');
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-client-profile-details`, {
        params: {
          compId: company_id,
          clientId: client_id,
        }
      });

      if (response.data.status === 200) {
        setClientProfile(response.data.data);
        setUpdatedProfile(response.data.data);
        getCitiesList(response.data.data.state);
        setIsLoading(false);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const showCompanyProfileDetails = async () => {
    setActiveStatus('companyStatus');
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-company-profile-details`, {
        params: {
          compId: company_id,
          clientId: client_id,
        }
      });

      if (response.data.status === 200) {
        setCompanyProfile(response.data.data);
        setUpdatedCompProfile(response.data.data);
        getCitiesList(response.data.data.gst_state);
        setIsLoading(false);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const showDocumentProfileDetails = async () => {
    setActiveStatus('documentStatus');
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-document-profile-details`, {
        params: {
          compId: company_id,
          clientId: client_id,
        }
      });

      if (response.data.status === 200) {

        setDocumentProfile(response.data.data);
        setIsLoading(false);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const showPackageProfileDetails = async () => {
    setActiveStatus('packageStatus');
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-package-profile-details`, {
        params: {
          compId: company_id,
          clientId: client_id,
        }
      });

      if (response.data.status === 200) {
        setPackageProfile(response.data.data);
        setIsLoading(false);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const showChangesHistoryDetails = async () => {
    setActiveStatus('changesHistory');
    setIsLoading(true);
    try {
      const response = await dashboardApi.get(`get-changes-history-details`, {
        params: {
          compId: company_id,
          clientId: client_id,
        }
      });

      if (response.data.status === 200) {
        setChangesHistory(response.data.data);
        setIsLoading(false);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  

  const getStateDetails = async () => {
    try {
      const response = await dashboardApi.get(`admin-get-client-state-list`);
      if (response.data.status === 200) {
        setStateDetails(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const getCitiesList = async (state_id) => {
    try {
      const response = await dashboardApi.get(`admin-get-client-cities-list`, {
        params: {
          stateId: state_id
        }
      });

      if (response.data.status === 200) {
        setCitiesDetails(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const getGroupList = async () => {
    try {
      const response = await dashboardApi.get(`admin-get-client-group-list`);
      if (response.data.status === 200) {
        setGroupDetails(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }


  const showPopup = (message, error) => {
    setPopupMessage(message);
    setIsError(error);
    setTimeout(() => {
      setPopupMessage("");
    }, 5000);
  };

  const renderField = (label, field) => (
    <>
      {field == 'address' ?
        <div className="col-sm-8">
          <small>{label}</small>

          <div className="d-flex align-items-center">
            <input
              type="text"
              className="form-control"
              value={updatedProfile[field]}
              readOnly={!editMode[field]}
              onChange={(e) => handleChange(e, field)}
            />

            {!editMode[field] ? (
              <button
                className="btn btn-sm btn-primary ml-2"
                onClick={() => handleEdit(field)}
              >
                <span class="material-icons-outlined">
                  edit_note
                </span>
              </button>
            ) : (
              <button
                className="btn btn-sm btn-success ml-2"
                onClick={() => handleSave(field)}
              >
                Save
              </button>
            )}
          </div>
        </div>
        : field == 'state' ?
          <div className="col-sm-3">
            <small>{label}</small>

            <div className="d-flex align-items-center">
              <select className="form-control"
                value={updatedProfile[field]}
                readOnly={!editMode[field]}
                onChange={(e) => handleChange(e, field)}>
                {stateDetails && stateDetails.map((sd) => (
                  <option value={sd.id}>{sd.state_name}</option>
                ))}
              </select>



              {!editMode[field] ? (
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => handleEdit(field)}
                >
                  <span class="material-icons-outlined">
                    edit_note
                  </span>
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-success ml-2"
                  onClick={() => handleSave(field)}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          : field == 'city' ?
            <div className="col-sm-3">
              <small>{label}</small>

              <div className="d-flex align-items-center">
                <select className="form-control"
                  value={updatedProfile[field]}
                  readOnly={!editMode[field]}
                  onChange={(e) => handleChange(e, field)}>
                  {citiesDetails && citiesDetails.map((cd) => (
                    <option value={cd.id}>{cd.city_name}</option>
                  ))}
                </select>

                {!editMode[field] ? (
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => handleEdit(field)}
                  >
                    <span class="material-icons-outlined">
                      edit_note
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-success ml-2"
                    onClick={() => handleSave(field)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            :
            field == 'group_id' ?
              <div className="col-sm-3">
                <small>{label}</small>

                <div className="d-flex align-items-center">
                  <select className="form-control"
                    value={updatedProfile[field]}
                    readOnly={!editMode[field]}
                    onChange={(e) => handleChange(e, field)}>
                    {groupDetails && groupDetails.map((cd) => (
                      <option value={cd.group_id}>{cd.group_name}</option>
                    ))}
                  </select>

                  {!editMode[field] ? (
                    <button
                      className="btn btn-sm btn-primary ml-2"
                      onClick={() => handleEdit(field)}
                    >
                      <span class="material-icons-outlined">
                        edit_note
                      </span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-success ml-2"
                      onClick={() => handleSave(field)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
              : field == 'zipcode' ?
                <div className="col-sm-3">
                  <small>{label}</small>

                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control"
                      value={updatedProfile[field]}
                      readOnly={!editMode[field]}
                      onChange={(e) => handleChange(e, field)}
                    />


                    {!editMode[field] ? (
                      <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => handleEdit(field)}
                      >
                        <span class="material-icons-outlined">
                          edit_note
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-success ml-2"
                        onClick={() => handleSave(field)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                  {validationErrors.clientZipcode && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>
                      {validationErrors.clientZipcode}
                    </div>
                  )}
                </div>

                : field == 'mobile_no' ?
                  <div className="col-sm-3">
                    <small>{label}</small>

                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        value={updatedProfile[field]}
                        readOnly={!editMode[field]}
                        onChange={(e) => handleChange(e, field)}
                      />


                      {!editMode[field] ? (
                        <button
                          className="btn btn-sm btn-primary ml-2"
                          onClick={() => handleEdit(field)}
                        >
                          <span class="material-icons-outlined">
                            edit_note
                          </span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-success ml-2"
                          onClick={() => handleSave(field)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    {validationErrors.clientMobileNumber && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>
                        {validationErrors.clientMobileNumber}
                      </div>
                    )}
                  </div>
                  : field == 'pan_card' ?
                    <div className="col-sm-3">
                      <small>{label}</small>

                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          value={updatedProfile[field]}
                          readOnly={!editMode[field]}
                          onChange={(e) => handleChange(e, field)}
                        />
                        {!editMode[field] ? (
                          <button
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() => handleEdit(field)}
                          >
                            <span class="material-icons-outlined">
                              edit_note
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success ml-2"
                            onClick={() => handleSave(field)}
                          >
                            Save
                          </button>
                        )}
                      </div>
                      {validationErrors.clientPanCard && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>
                          {validationErrors.clientPanCard}
                        </div>
                      )}
                    </div>
                    : field == 'adhar_card' ?
                      <div className="col-sm-3">
                        <small>{label}</small>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            value={updatedProfile[field]}
                            readOnly={!editMode[field]}
                            onChange={(e) => handleChange(e, field)}
                          />
                          {!editMode[field] ? (
                            <button
                              className="btn btn-sm btn-primary ml-2"
                              onClick={() => handleEdit(field)}
                            >
                              <span class="material-icons-outlined">
                                edit_note
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-success ml-2"
                              onClick={() => handleSave(field)}
                            >
                              Save
                            </button>
                          )}
                        </div>
                        {validationErrors.clientAdharCard && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>
                            {validationErrors.clientAdharCard}
                          </div>
                        )}
                      </div>
                      :
                      <div className="col-sm-3">
                        <small>{label}</small>

                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            value={updatedProfile[field]}
                            readOnly={!editMode[field]}
                            onChange={(e) => handleChange(e, field)}
                          />

                          {!editMode[field] ? (
                            <button
                              className="btn btn-sm btn-primary ml-2"
                              onClick={() => handleEdit(field)}
                            >
                              <span class="material-icons-outlined">
                                edit_note
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-success ml-2"
                              onClick={() => handleSave(field)}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
      }
    </>
  );

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handlePanEdit = (id, doc_number) => {
    setEditPanMode((prev) => ({ ...prev, [id]: true }));
    setPanCardComp((prev) => ({ ...prev, [id]: doc_number }));
    setPaneReadOnly(false);
  };

  const handlePanChange = (value, id) => {
    setPanCardComp((prev) => ({ ...prev, [id]: value }));
  };



  const handlePanSave = async (id, doc_id) => {
    setEditPanMode((prev) => ({ ...prev, [id]: false }));
    const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const panNumber = panCardComp[id];

    if (doc_id === 1) {
      if (!panNumber || panNumber.length !== 10 || !panCardPattern.test(panNumber)) {
        showPopup("Enter a valid 10-character PAN card number (e.g., ABCDE1234F)", true);
        return;
      }

      try {
        const response = await dashboardApi.get(`check-company-pancard-number/${panNumber}`);
        if (response.data === 1) {
          showPopup(`Pancard already added in our system. (${panNumber})`, true);
          return;
        }
      } catch (error) {
        showPopup("Error checking PAN number", true);
        return;
      }
    }

    // Save to API
    try {
      const response = await dashboardApi.post("admin-update-doc-number-update", {
        id: id,
        exeId: emp_id,
        docNumber: panNumber,
      });

      if (response) {
        showPopup(response.data.message, false);
        showDocumentProfileDetails();
      }
      else {
        showPopup(response.data.message, true);
      }
    }
    catch (error) {
      showPopup("Something went wrong. Please try again.", true);
    }
  };

  const handleSave = async (field) => {
    if (field == 'zipcode') {
      const errors = {};
      const value = updatedProfile[field];

      if (!/^\d{0,6}$/.test(value)) {
        errors.clientZipcode = 'ZIP code must be a 6-digit numeric value.';
      }
      setValidationErrors(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }

    if (field == 'mobile_no') {
      const errors = {};
      const value = updatedProfile[field];

      if (!/^\d{0,10}$/.test(value)) {
        errors.clientMobileNumber = 'Mobile number should be numeric and it should be 10 digit.';
      }
      else {
        try {
          const clientId = updatedProfile.id;
          const response = await dashboardApi.get(`admin-check-client-mobile-number`, {
            params: {
              id: clientId,
              field,
              value: updatedProfile[field],
            }
          });

          if (response.data.status == 201) {
            errors.clientMobileNumber = response.data.message;
          }

        }
        catch (error) {
          errors.clientMobileNumber = error;
        }
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }


    if (field == 'pan_card') {
      const errors = {};
      const value = updatedProfile[field];

      const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (value.length !== 10 || !panCardPattern.test(value)) {
        errors.clientPanCard = 'Enter a valid 10-character PAN card number (e.g., ABCDE1234F)';
      }
      else {
        const response = await dashboardApi.get(`check-company-pancard-number/${value}`);
        if (response.data === 1) {
          errors.clientPanCard = 'Pancard already added in our system. ( ' + value + ' )';
        }
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }

    if (field == 'adhar_card') {
      const errors = {};
      const value = updatedProfile[field];
      if (!/^\d{0,12}$/.test(value)) {
        errors.clientAdharCard = 'Enter a valid 12-character Adhar card number (e.g., 678923456789)';
      }
      else {
        const response = await dashboardApi.get(`admin-client-adharcard-number/${value}`);
        if (response.data === 1) {
          errors.clientAdharCard = 'Adhar Card already added in our system. ( ' + value + ' )';
        }
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }

    try {
      const clientId = updatedProfile.id;

      const response = await dashboardApi.post("admin-update-client-info", {
        id: clientId,
        exeId: emp_id,
        field,
        value: updatedProfile[field],
      });
      if (response) {
        showPopup(response.data.message, false);
        setEditMode({ ...editMode, [field]: false });
      }
      else {
        showPopup(response.data.message, true);
      }
    }
    catch (error) {
      showPopup("Something went wrong. Please try again.", true);
    }
  };

  const handleChange = (e, field) => {
    const errors = {};
    if (field == 'state') {
      getCitiesList(e.target.value)
    }
    setUpdatedProfile({ ...updatedProfile, [field]: e.target.value });
  };

  const newCompanyDocument = () => {
    setNewUploadDocumnet(true);
  }
  const closeNewUpdateDocModal = () => {
    setNewUploadDocumnet(false);
    emptyNewDocument();
  }

  const getDocumentListing = async () => {
    try {
      const response = await dashboardApi.get(`get-document-listing`);
      if (response.data.status === 200) {
        setDocumentListing(response.data.data);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);

    }
  }

  //comp Profile Start
  const renderCompField = (label, field) => (
    <>
      {field == 'address' ?
        <div className="col-sm-8">
          <small>{label}</small>
          <div className="d-flex align-items-center">
            <input
              type="text"
              className="form-control"
              value={updatedCompProfile[field]}
              readOnly={!editCompMode[field]}
              onChange={(e) => handleCompChange(e, field)}
            />

            {!editCompMode[field] ? (
              <button
                className="btn btn-sm btn-primary ml-2"
                onClick={() => handleCompEdit(field)}
              >
                <span class="material-icons-outlined">
                  edit_note
                </span>
              </button>
            ) : (
              <button
                className="btn btn-sm btn-success ml-2"
                onClick={() => handleCompSave(field)}
              >
                Save
              </button>
            )}
          </div>
        </div>
        :
        field == 'business_name' ?
          <div className="col-sm-6">
            <small>{label}</small>

            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                value={updatedCompProfile[field]}
                readOnly={!editCompMode[field]}
                onChange={(e) => handleCompChange(e, field)}
              />

              {!editCompMode[field] ? (
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => handleCompEdit(field)}
                >
                  <span class="material-icons-outlined">
                    edit_note
                  </span>
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-success ml-2"
                  onClick={() => handleCompSave(field)}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          : field == 'gst_state' ?
            <div className="col-sm-3">
              <small>{label}</small>

              <div className="d-flex align-items-center">
                <select className="form-control"
                  value={updatedCompProfile[field]}
                  readOnly={!editCompMode[field]}
                  onChange={(e) => handleCompChange(e, field)}>
                  {stateDetails && stateDetails.map((sd) => (
                    <option value={sd.id}>{sd.state_name}</option>
                  ))}
                </select>



                {!editCompMode[field] ? (
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => handleCompEdit(field)}
                  >
                    <span class="material-icons-outlined">
                      edit_note
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-success ml-2"
                    onClick={() => handleCompSave(field)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            : field == 'city' ?
              <div className="col-sm-3">
                <small>{label}</small>

                <div className="d-flex align-items-center">
                  <select className="form-control"
                    value={updatedCompProfile[field]}
                    readOnly={!editCompMode[field]}
                    onChange={(e) => handleCompChange(e, field)}>
                    {citiesDetails && citiesDetails.map((cd) => (
                      <option value={cd.id}>{cd.city_name}</option>
                    ))}
                  </select>

                  {!editCompMode[field] ? (
                    <button
                      className="btn btn-sm btn-primary ml-2"
                      onClick={() => handleCompEdit(field)}
                    >
                      <span class="material-icons-outlined">
                        edit_note
                      </span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-success ml-2"
                      onClick={() => handleCompSave(field)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
              :
              field == 'group_id' ?
                <div className="col-sm-3">
                  <small>{label}</small>

                  <div className="d-flex align-items-center">
                    <select className="form-control"
                      value={updatedCompProfile[field]}
                      readOnly={!editCompMode[field]}
                      onChange={(e) => handleCompChange(e, field)}>
                      {groupDetails && groupDetails.map((cd) => (
                        <option value={cd.group_id}>{cd.group_name}</option>
                      ))}
                    </select>

                    {!editCompMode[field] ? (
                      <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => handleCompEdit(field)}
                      >
                        <span class="material-icons-outlined">
                          edit_note
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-success ml-2"
                        onClick={() => handleCompSave(field)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
                : field == 'zipcode' ?
                  <div className="col-sm-3">
                    <small>{label}</small>

                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        value={updatedCompProfile[field]}
                        readOnly={!editCompMode[field]}
                        onChange={(e) => handleCompChange(e, field)}
                      />


                      {!editCompMode[field] ? (
                        <button
                          className="btn btn-sm btn-primary ml-2"
                          onClick={() => handleCompEdit(field)}
                        >
                          <span class="material-icons-outlined">
                            edit_note
                          </span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-success ml-2"
                          onClick={() => handleCompSave(field)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    {validationErrors.compZipcode && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>
                        {validationErrors.compZipcode}
                      </div>
                    )}
                  </div>

                  : field == 'mobile_no' ?
                    <div className="col-sm-3">
                      <small>{label}</small>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          value={updatedCompProfile[field]}
                          readOnly={!editCompMode[field]}
                          onChange={(e) => handleCompChange(e, field)}
                        />
                        {!editCompMode[field] ? (
                          <button
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() => handleCompEdit(field)}
                          >
                            <span class="material-icons-outlined">
                              edit_note
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success ml-2"
                            onClick={() => handleCompSave(field)}
                          >
                            Save
                          </button>
                        )}
                      </div>

                      {validationErrors.compMobileNumber && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>
                          {validationErrors.compMobileNumber}
                        </div>
                      )}
                    </div>

                    : field == 'pan_card' ?
                      <div className="col-sm-3">
                        <small>{label}</small>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            value={updatedCompProfile[field]}
                            readOnly={!editCompMode[field]}
                            onChange={(e) => handleCompChange(e, field)}
                          />
                          {!editCompMode[field] ? (
                            <button
                              className="btn btn-sm btn-primary ml-2"
                              onClick={() => handleCompEdit(field)}
                            >
                              <span class="material-icons-outlined">
                                edit_note
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-success ml-2"
                              onClick={() => handleCompSave(field)}
                            >
                              Save
                            </button>
                          )}
                        </div>

                        {validationErrors.compPanCard && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>
                            {validationErrors.compPanCard}
                          </div>
                        )}
                      </div>
                      :
                      <div className="col-sm-3">
                        <small>{label}</small>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            value={updatedCompProfile[field]}
                            readOnly={!editCompMode[field]}
                            onChange={(e) => handleCompChange(e, field)}
                          />

                          {!editCompMode[field] ? (
                            <button
                              className="btn btn-sm btn-primary ml-2"
                              onClick={() => handleCompEdit(field)}
                            >
                              <span class="material-icons-outlined">
                                edit_note
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-success ml-2"
                              onClick={() => handleCompSave(field)}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
      }
    </>
  );

  const handleCompEdit = (field) => {
    setEditCompMode({ ...editCompMode, [field]: true });
  };

  const handleCompSave = async (field) => {
    console.log(updatedCompProfile);
    if (field == 'zipcode') {
      const errors = {};
      const value = updatedCompProfile[field];

      if (!/^\d{0,6}$/.test(value)) {
        errors.compZipcode = 'ZIP code must be a 6-digit numeric value.';
      }
      setValidationErrors(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }

    if (field == 'mobile_no') {
      const errors = {};
      const value = updatedCompProfile[field];
      if (!/^\d{0,10}$/.test(value)) {
        errors.clientMobileNumber = 'Mobile number should be numeric and it should be 10 digit.';
      }
      else {
        try {
          const compId = updatedCompProfile.comp_id;

          const response = await dashboardApi.get(`admin-check-client-mobile-number`, {
            params: {
              id: compId,
              field,
              value: updatedCompProfile[field],
            }
          });

          if (response.data.status == 201) {
            errors.compMobileNumber = response.data.message;
          }

        }
        catch (error) {
          errors.compMobileNumber = error;
        }
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }


    if (field == 'pan_card') {
      const errors = {};
      const value = updatedCompProfile[field];

      const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (value.length !== 10 || !panCardPattern.test(value)) {
        errors.compPanCard = 'Enter a valid 10-character PAN card number (e.g., ABCDE1234F)';
      }
      else {
        const response = await dashboardApi.get(`check-company-pancard-number/${value}`);
        if (response.data === 1) {
          errors.compPanCard = 'Pancard already added in our system. ( ' + value + ' )';
        }
      }

      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      else {
        setValidationErrors('');
      }
    }

    try {

      const companyId = updatedCompProfile.comp_id;
      const clientIds = updatedCompProfile.client_id;

      const response = await dashboardApi.post("admin-update-company-info", {
        comp_id: companyId,
        client_id: clientIds,
        exeId: emp_id,
        field,
        value: updatedCompProfile[field],
      });
      if (response) {
        showPopup(response.data.message, false);
        setEditCompMode({ ...editCompMode, [field]: false });
      }
      else {
        showPopup(response.data.message, true);
      }
    }
    catch (error) {
      showPopup("Something went wrong. Please try again.", true);
    }
  };

  const handleCompChange = (e, field) => {
    if (field == 'gst_state') {
      getCitiesList(e.target.value)
    }
    setUpdatedCompProfile({ ...updatedCompProfile, [field]: e.target.value });
  };


  const uplodeDocument = async (client_id, id) => {
    if (documentFile.length < 1) {
      showPopup('Select at least one file to upload', true)
      return;

    }

    const formData = new FormData();
    for (let i = 0; i < documentFile.length; i++) {
      formData.append('document_file[]', documentFile[i]);
    }
    formData.append('document_id', id);
    formData.append('client_id', client_id);
    try {
      const response = await dashboardApi.post('admin-uplode-company-document', formData);
      if (response.data.status === 200) {
        showPopup(response.data.message, false)
        setDocumentFile([]);
        showDocumentProfileDetails();
      }
      else {
        showPopup(response.data.message, true)
      }
    }
    catch (error) {
      const errorMsg = "Something went wrong while uploading the file";
      showPopup(response.data.message, true)
    }
  }

  const uploadNewDocument = async () => {

    if (documentFile.length < 1) {
      showPopup("Select at least one file to upload.", true);
      return;
    }

    if (!newDocumentType) {
      showPopup("Select a document type.", true);
      return;
    }

    if (!newDocumentNo) {
      showPopup("Enter a document number or name.", true);
      return;
    }

    const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (newDocumentType === '1') {
      if (!panCardPattern.test(newDocumentNo)) {
        showPopup("Enter a valid 10-character PAN card number (e.g., ABCDE1234F).", true);
        return;
      }

      try {
        const response = await dashboardApi.get(`check-company-pancard-number/${newDocumentNo}`);
        if (response.data === 1) {
          showPopup(`PAN card already exists in our system. (${newDocumentNo})`, true);
          return;
        }
      } catch (error) {
        showPopup("Error while checking PAN number. Please try again.", true);
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < documentFile.length; i++) {
      formData.append('document_file[]', documentFile[i]);
    }

    formData.append('comp_id', company_id);
    formData.append('client_id', client_id);
    formData.append('doc_type', newDocumentType);
    formData.append('document_no', newDocumentNo);
    formData.append('created_by', emp_id);

    try {
      const response = await dashboardApi.post('admin-uplode-new-company-document', formData);
      if (response.data.status === 200) {
        showDocumentProfileDetails();
        showPopup(response.data.message);
        emptyNewDocument();
      }
      else {
        showPopup(response.data.message);
      }
    }
    catch (error) {
      showPopup('An error occurred while creating the product', true);
    }
  };

  const emptyNewDocument = () =>
  {
    setNewDocumentNumber('');
    setNewDocumentType('');
    setUpdateDocumentType('');
    setDocUpdatedId('');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <LoadingModal isLoading={isLoading} />

                <div className="row border-bottom mb-2 pb-3">
                  <div class="col-sm-12">
                    <AdminDetailsHeader></AdminDetailsHeader>
                  </div>
                </div>

                <div className="row border-bottom mb-2 pb-3">
                  <div className="col-sm-4">
                    <small>Company Mobile Number</small>
                    <input type="text" className="form-control" value={mobileNo} onChange={handleMobileNumberChange} />
                  </div>

                  <div className="col-sm-2 d-flex justify-content-center align-items-end">
                    <label>Or</label>
                  </div>

                  <div className="col-sm-4">
                    <small>Business Name </small>
                    <input type="text" className="form-control" value={businessName} onChange={(e) => { setBusinessName(e.target.value) }} />
                  </div>

                  <div className="col-sm-2 d-flex justify-content-center align-items-end">
                    <button className="btn btn-primary btn-sm" onClick={searchCompanyDetails}>Search</button>
                  </div>

                  {validationErrors.searchValidation && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>
                      {validationErrors.searchValidation}
                    </div>
                  )}
                </div>

                <div className="row">
                  

                  <table className="table table-bordered table-hovered" style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Client Name</th>
                        <th>Company Name</th>
                        <th>Contact Info</th>
                        <th>Pan Card</th>
                        <th>Exe Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyDetails && companyDetails.length > 0 ? (
                        companyDetails.map((cd, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{cd.client_name}</td>
                            <td>{cd.business_name}</td>
                            <td>{cd.mobile_no}<br />{cd.email}</td>
                            <td>{cd.pan_card}</td>
                            <td>{cd.emp_name}</td>
                            <td>
                              {cd.status === 0 ? <span style={{ color: 'red' }}>Pending</span>
                                : cd.status === 1 ? <span style={{ color: 'red' }}>Verified</span>
                                  : cd.status === 2 ? <span style={{ color: 'green' }}>Active</span>
                                    : cd.status === 3 ? <span style={{ color: 'red' }}>Inactive</span>
                                      : cd.status === 4 ? <span style={{ color: 'red' }}>Expire</span>
                                        : cd.status === 5 ? <span style={{ color: 'red' }}>Blacklist</span>
                                          : cd.status === 6 ? <span style={{ color: 'red' }}>Deleted</span>
                                            : ''}
                            </td>
                            <td>
                              <button className='btn btn-info btn-sm' onClick={() => showCompanyDetails(cd.comp_id, cd.client_id)}>
                                View
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                            
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>

                {profile_status &&
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-between flex-wrap align-items-center" style={{ overflowY: "hidden" }}>
                      <div className='d-flex align-items-center'>
                        <div className="p-1">
                          <button className={`btn btn-primary small tabss ${clientStatus}`} style={{ width: "max-content" }} onClick={() => {
                            showClientProfileDetails("clientInfo");
                          }}>Client Info
                          </button>
                        </div>

                        <div className="p-1">
                          <button className={`btn btn-primary small tabss ${compStatus}`} style={{ width: "max-content" }} onClick={() => {
                            showCompanyProfileDetails("companyInfo");
                          }}>Company info

                          </button>
                        </div>

                        <div className="p-1">
                          <button className={`btn btn-primary small tabss ${docStatus}`} style={{ width: "max-content" }} onClick={() => {
                            showDocumentProfileDetails("documentInfo");
                          }}>Document Info

                          </button>
                        </div>
                        <div className="p-1">
                          <button className={`btn btn-primary small tabss ${packageStatus}`} style={{ width: "max-content" }} onClick={() => {
                            showPackageProfileDetails("packageInfo");
                          }}>Package Info
                          </button>
                        </div>

                        <div className="p-1">
                          <button className={`btn btn-primary small tabss ${changesStatus}`} style={{ width: "max-content" }} onClick={() => {
                            showChangesHistoryDetails("changesHistory");
                          }}>Changes History
                          </button>
                        </div>
                      </div>
                      
                      {docStatus === 'active' && (
                        <div className="p-1">
                          <Link className='btn btn-info btn-sm' style={{ float: 'right' }} onClick={() => newCompanyDocument()}>+
                          </Link>
                        </div>
                      )}
                    </div>

                    {clientStatus == 'active' &&
                      <div className="d-flex flex-wrap">
                        {renderField("Client Name", "client_name")}
                        {renderField("Mobile Number", "mobile_no")}
                        {renderField("Email Id", "email")}
                        {renderField("Pan Card", "pan_card")}
                        {renderField("Adhar Card", "adhar_card")}
                        {renderField("State Name", "state")}
                        {renderField("City Name", "city")}
                        {renderField("Zipcode", "zipcode")}
                        {renderField("Group Name", "group_id")}
                        {renderField("Address", "address")}
                      </div>
                    }

                    {compStatus == 'active' &&
                      <div className="d-flex flex-wrap">
                        {renderCompField("Business Name", "business_name")}
                        {renderCompField("Mobile Number", "mobile_no")}
                        {renderCompField("Email Id", "email")}
                        {renderCompField("Pan Card", "pan_card")}
                        {renderCompField("State Name", "gst_state")}
                        {renderCompField("City Name", "city")}
                        {renderCompField("Zipcode", "zipcode")}
                        {renderCompField("Address", "address")}
                      </div>
                    }

                    {docStatus === 'active' && (
                      <>
                        {documentProfile && documentProfile.map((dp) => (
                          <div className="d-flex flex-wrap col-12 my-3 border py-3 align-items-center rounded" key={dp.id}>
                            <div className="col-sm-3" key={dp.id}>
                              <small>{dp.document_name}</small>
                              <input
                                type="text"
                                className="form-control"
                                value={editPanMode[dp.id] ? panCardComp[dp.id] : dp.doc_number}
                                readOnly={!editPanMode[dp.id]}
                                onChange={(e) => handlePanChange(e.target.value, dp.id)}
                              />
                              {editPanMode[dp.id] ? (
                                <button className="btn btn-sm btn-success ml-2" onClick={() => handlePanSave(dp.id, dp.doc_type_id)}>
                                  Save
                                </button>
                              ) : (
                                <button className="btn btn-sm btn-primary ml-2" onClick={() => handlePanEdit(dp.id, dp.doc_number)}>
                                  <span className="material-icons-outlined">edit_note</span>
                                </button>
                              )}
                            </div>

                            <div className="col-sm-6 d-flex pb-2 px-0" style={{ overflowY: "hidden" }}>
                              {dp.doc_url && dp.doc_url.split(',').map((item, index) => (
                                <div className="col-sm-4 d-flex flex-column position-relative justify-content-center px-1" key={index}>
                                  <iframe src={'https://apiworkforce.r-ims.com/' + item} title="document" style={{ width: '100%', height: '200px' }}></iframe>
                                  <div className='col-12 text-center position-absolute start-0'>
                                    <a href={'https://apiworkforce.r-ims.com/' + item} target='_blank' rel="noopener noreferrer" className="btn btn-primary">View</a>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="col-sm-3 text-center">
                              {dp.status === 0 && <span style={{ color: 'red' }}>Pending</span>}
                              {dp.status === 1 && <span style={{ color: 'green' }}>Approved</span>}
                              {dp.status === 2 && <span style={{ color: 'red' }}>Rejected</span>}
                              <br />
                              <input className="form-control" type="file" multiple onChange={handleFileChange} />
                              {validationErrors.documentFile && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.documentFile}</div>
                              )}
                              <span className="btn-primary btn-sm" onClick={() => uplodeDocument(dp.guest_client_id, dp.id)}>
                                Upload
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    
                    {packageStatus == 'active' &&
                      <div className="d-flex flex-wrap">
                        <table className="table table-bordered table-hovered">
                          <tr style={{ fontWeight: '700' }}>
                            <td>Package Id</td>
                            <td>Package Name</td>
                            <td>Duration</td>
                            <td>Product</td>
                            <td>Service</td>
                            <td>Category</td>
                            <td>Status</td>
                            <td>Exe Name</td>
                            <td>Created Date</td>
                          </tr>
                          {packageProfile && packageProfile.map((pp) => (
                            <>
                            <tr>
                              <td rowSpan="2">{pp.package_id}</td>
                              <td>{pp.package_name}</td>
                              <td>{pp.package_duration == 1 ? 'Monthly' : 'Unlimited'}</td>
                              <td>{pp.product_name}</td>
                              <td>{pp.service_name}</td>
                              <td>{pp.category_names}</td>
                              <td>
                                {pp.package_status == 0 ? <span style={{ color: 'red' }}>Pending </span>
                                  : pp.package_status == 1 ? <span style={{ color: 'green' }}>Active</span>
                                    : pp.package_status == 2 ? <span style={{ color: 'red' }}>Stop By(admin,sales,client)</span>
                                      : pp.package_status == 3 ? <span style={{ color: 'red' }}>Stop due to Due Payment</span> : <span style={{ color: 'red' }}>Expire</span>
                                }
                              </td>

                              <td>{pp.emp_name}</td>
                              <td>{pp.created_date}</td>
                            </tr>

                            <tr style={{borderBottom:'solid #657789 4px'}}>
                              <td colspan="9"><b> Package Group Name : </b>{pp.package_group_name}</td>
                            </tr>
                            </>
                          ))}

                        </table>
                      </div>
                    }

                    {changesStatus == 'active' &&
                      <div className="d-flex flex-wrap">
                        <table className="table table-bordered table-hovered">
                          <tr style={{ fontWeight: '700' }}>
                            <td>Id</td>
                            <td>Comp Id</td>
                            <td>Old Data</td>
                            <td>Updated Data</td>
                            <td>Remarks</td>
                            <td>Created By</td>
                            <td>Created Date</td>
                           
                          </tr>
                          {changesHistory && changesHistory.map((ch) => (
                            <>
                            <tr>
                              <td>{ch.id}</td>
                              <td>{ch.comp_id}</td>
                              <td>{ch.old_data}</td>
                              <td>{ch.new_data}</td>
                              <td>{ch.remarks}</td>
                              <td>{ch.emp_name}</td>
                              <td>{ch.created_date}</td>
                            </tr>
                            </>

                          ))}

                        </table>
                      </div>
                    }

                  </div>
                }

                {popupMessage && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "20px",
                      right: "20px",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      backgroundColor: isError ? "red" : "green",
                      color: "white",
                      fontSize: "14px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                    }}
                  >
                    {popupMessage}
                  </div>
                )}
              </div>


            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={newUploadDocumnet} fullWidth PaperProps={{ style: { padding: '20px', maxWidth: '50%' } }}>
        <DialogTitle>Upload New Document</DialogTitle>
        <DialogContent style={{ height: "500px" }}>
          <form>
            <div className="row">
              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Update Request</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="col-sm-4 col-12">
                <label>Select Type</label>
                <select className="form-control" value={newDocumentType} onChange={(e) => { setNewDocumentType(e.target.value) }} >
                  <option value="">Select Type</option>
                  {documentListing && documentListing.map((dl) => (
                    <option value={dl.id}> {dl.document_name} </option>
                  ))}
                </select>
                {validationErrors.newDocumentType && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.newDocumentType}</div>
                )}
              </div>

              <div className="col-sm-4 col-12">
                <label>Document No</label>
                <input
                  type="text"
                  className="form-control"
                  value={newDocumentNo}
                  placeholder="Enter Document No"
                  onChange={(e) => setNewDocumentNumber(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                />
                {validationErrors.newDocumentNo && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.newDocumentNo}
                  </div>
                )}
              </div>

              <div className="col-sm-4 col-12">
                <label>Select Document</label>
                <input className="form-control" type="file" multiple onChange={handleFileChange} />

                {validationErrors.documentFile && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.documentFile}</div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeNewUpdateDocModal}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={uploadNewDocument}>Upload</Link>
        </DialogActions>

      </Dialog>
    </DashboardLayout>

  );
}
export default Admin_client_details;
